import React from 'react';
import PublicLayout from '../../layouts/Public';

const TermsView = () => {
  return (
    <PublicLayout>
      <div className="p-4">
        <h1>Terms &amp; Conditions</h1>
        <p><strong>1.&nbsp;&nbsp;&nbsp;&nbsp; General Conditions of Use (compeatnutrition.com)</strong></p>
        <p>Please read this information carefully before accessing or using the information and services available through the compeatnutrition.com website which is owned and operated by Compeat Nutrition Pty Ltd (ACN 608 631 983). By accessing or using the site compeatnurition.com and/or registering as a user, you agree to be bound by the terms and conditions below. Compeat Nutrition reserves the right to modify this agreement at any time, and such modifications shall be effective immediately upon posting a modified agreement on the Compeat Nutrition website; www.compeatnutrition.com.</p>
        <p>By clicking “I accept”, you agree to be personally bound by these terms and conditions, whether for yourself or on behalf of a minor (as determined by the jurisdiction in which you reside), if you are registering on behalf of a minor.</p>
        <p>By clicking “I accept”, you represent and warrant that you are at least 18 years old (or the age of majority in your jurisdiction, if it is not 18), and if you are registering on behalf of a minor, that you are their legal guardian.</p>
        <p>These General Conditions of Use apply to the use of this Website, including the use of the information services provided through this Website.</p>
        <p>A link to the General Conditions of Use will be published on the user registration forms on all websites owned and operated by Compeat Nutrition.</p>
        <p>Upon registration, you will be provided with a password and an account. You must not disclose these details to any other person or allow anyone else to use your account and password. Responsibility for any access to your account is seen to be the responsibility of the registered individual, and acknowledge that any access to, or use of your account by means of the password associated with that account is deemed to be access or use by the registered individual or their legal guardian. This includes any purchases made through your account which you agree to pay for in line with these general conditions of use and the manner specified on the Website.</p>
        <p><strong>2.&nbsp;&nbsp;&nbsp;&nbsp; Seek advice from Medical Professionals</strong></p>
        <p>While the Dietitians at Compeat Nutrition are experts in the field of Sports Dietetics you agree to make aware to your dietitian as well as immediately seek medical attention if there are any unanticipated changes to your health at any time.</p>
        <p>You must consult a qualified medical professional if you have any questions concerning your medical condition or injury if not already referred to Compeat Nutrition via a qualified medical professional</p>
        <p><strong>3.&nbsp;&nbsp;&nbsp;&nbsp; Information does not represent professional advice</strong></p>
        <p>You acknowledge and agree that the information published by Compeat Nutrition; exclusive of specific professional advice, is intended to provide general information in summary form. All information provided by us on the Website is provided in good faith. We derive our information from sources which we believe to be accurate and up to date as at the date of publication.</p>
        <p>It is acknowledged that any specific advice is intended for the individual to whom it has been prescribed. Compeat Nutrition takes no liability for the supply of information outside the duties associated with its provision to a serviced client.</p>
        <p>Compeat Nutrition Pty Ltd will not be liable to you or anyone else for any decision made or action taken by you or anyone else in reliance upon any information contained on or omitted from the Compeat Nutrition Site. In addition, to the extent permitted by law, we do not make any representations or warranties that any information we provide is reliable, accurate or complete and we make no guarantees of any specific result from use of this Website or the information service provided through it. To the extent permitted by law, we are not liable for any loss arising from any action taken or reliance by you on any information or material available on the Website.</p>
        <p>Professional Advice is deemed as advice provided to an individual specific to their circumstances. Any information passed on and not designed for that specific individual is considered information. Our experts may provide health, fitness or nutritional information for educational purposes. They do not offer or provide professional medical advice, diagnosis, treatment or rehabilitation and may refer the member to certain third party resources. Any referrals to official bodies are not intended to be and should not be construed as an endorsement, promotion or recommendation by Compeat Nutrition.</p>
        <p>No agency, partnership, joint venture, employee-employer or franchisee-franchisor relationship is created or intended by this General Conditions of Use agreement or your use of Compeat Nutrition Services.</p>
        <p><strong>4.&nbsp;&nbsp;&nbsp;&nbsp; Intellectual property</strong></p>
        <p>Copyright in material, trademarks, and content accessed or supplied via the Compeat Nutrition website is owned by Compeat Nutrition or provided under licence by Compeat Nutrition. You agree not to infringe any intellectual property rights owned by Compeat Nutrition. This information includes but is not limited to all material and ideas contained within the Compeat Nutrition website and any Compeat Nutrition branded or supplied material.</p>
        <p><strong>5.&nbsp;&nbsp;&nbsp;&nbsp; No guarantees or endorsements of sites or services we link to or that link to us</strong></p>
        <p>Compeat Nutrition provides links and referral to Internet sites maintained by third parties. Such sites are not under the control of Compeat Nutrition, and therefore Compeat Nutrition takes no responsibility for the contents (including the accuracy, legality or decency) of any linked site or any link contained in a linked site. Compeat Nutrition is not responsible for the copyright compliance of any linked site and is not be liable for any damages or loss arising in any way out of or in connection with; or incidental, to any information, or service provided by any third party.</p>
        <p><strong>6.&nbsp;&nbsp;&nbsp;&nbsp; No guarantees or warranties as to the service</strong></p>
        <p>Compeat Nutrition provides no guarantee or warranty to you that the services generally available through Compeat Nutrition will perform to a certain standard of quality or be error-free. We do not guarantee or endorse any of the services provided by any third party. Compeat Nutrition does however agree to provide a high quality sports dietetic and dietetic service and help you achieve your goals.</p>
        <p>You are responsible for all transactions in which you choose to participate, including monitoring the status of, and complying with all relevant legal and other obligations, the agreements in which you enter and conditions by which they are entered.</p>
        <p><strong>7.&nbsp;&nbsp;&nbsp;&nbsp; Our rights to use information you send us</strong></p>
        <p>You are obliged to provide Compeat Nutrition with accurate, complete and current information. You may update your information via the Compeat Nutrition website and the Compeat nutrition user portal. Alternatively, you may submit your information via email to <a href="mailto:admin@compeatnutrition.com">admin@compeatnutrition.com</a>. All information that you provide to Compeat Nutrition will be treated in accordance with our privacy policy.</p>
        <p>Compeat Nutrition reserves the right to modify or delete any information you submit to the Compeat Nutrition and the user platform in order to enhance the services provided to you and other users.</p>
        <p>Compeat Nutrition welcomes feedback from you about all aspects of the Compeat Nutrition Site and the Services it provides. You agree that Compeat Nutrition may reproduce, distribute, transmit, create derivative works of, and publicly display any materials and other information, including ideas for new or improved products and services that you submit to any public or social media sites affiliated or associated with Compeat Nutrition. Personal information will not be sold or passed on for financial gain by Compeat Nutrition.</p>
        <p>Through the supply of Feedback as mentioned in the general terms and conditions you acknowledge and agree that Compeat Nutrition accepts no responsibility for liability, damage, injury or loss that may arise from feedback submitted to or published on the website, affiliated sites or social media and you agree to indemnify and release forever Compeat Nutrition from any liability it may incur arising, whether directly or indirectly, out of any feedback you post on the Compeat Nutrition website, affiliated sites or through Social media.</p>
        <p>Compeat Nutrition may at any time change or discontinue any feature of the Compeat Nutrition Site or user platform, including content, hours or availability and equipment required for access.</p>
        <p><strong>8.&nbsp;&nbsp;&nbsp;&nbsp; Security of your information</strong></p>
        <p>Compeat Nutrition shall take all due diligence in ensuring the privacy and integrity of the information you provide, including but not limited to measures such as firewalls, secure password access, secure servers and data encryption. Possibility exists that this information could be unlawfully observed or accessed by a third party while in transit over the internet or while stored on Compeat Nutrition hosted servers, systems or on the Compeat Nutrition Website. Compeat Nutrition accepts no liability should this occur. Compeat Nutrition also will not collect and retain your credit card information. This information is to be managed via a secure third party.</p>
        <p><strong>9.&nbsp;&nbsp;&nbsp;&nbsp; Your conduct</strong></p>
        <p>You agree to indemnify Compeat Nutrition against any legal fees, damages or expenses that may be incurred by Compeat Nutrition as a result of a breach of these General Conditions of Use.</p>
        <p>You warrant to Compeat Nutrition that you will not use the Compeat nutrition website, the user platform or any other Compeat Nutrition service for any purpose that is prohibited by the General Conditions of Use or that is illegal or unlawful. You agree to abide by all applicable laws and regulations.</p>
        <p>Applicable Laws includes, but is not limited to:</p>
        <ol>
          <li>The use of the Compeat Nutrition Site, Platform or Social Media Site to offend others (which shall be judged in the absolute opinion of Compeat Nutrition).</li>
          <li>Publishing, or in any way distributing or disseminating any information which is unlawful, obscene, defamatory, offensive or inappropriate or considered to be the Intellectual property of Compeat Nutrition. (judged in the absolute opinion of Compeat Nutrition);</li>
        </ol>
        <ul>
          <li>Making available; through direct or indirect use of the Compeat Nutrition website, any material that you do not have a right to make available. Inclusive of files or programs designed to interfere with the functioning of other software or hardware, breaching any laws or regulations applicable to your use of the website, or unlawful provision of information deemed to be intellectual property of Compeat Nutrition.</li>
        </ul>
        <ol>
          <li>bypass (or attempt to bypass) any security mechanisms imposed by the Website;</li>
          <li>harvest or collect email addresses, photographs or personal information of other users;</li>
          <li>impersonate any person or entity;</li>
        </ol>
        <ul>
          <li>post or transmit false or misleading material or make any form of misleading or deceptive representation;</li>
          <li>exploit the Website for your own commercial or unlawful purposes or the commercial or unlawful purposes of any other person (including the posting of advertisements, solicitors, promotional materials, “spam” or any other materials that are contrary to our commercial or lawful interests); Any commercial use of the Compeat Nutrition Site requires the prior written approval of Compeat Nutrition.</li>
        </ul>
        <ol>
          <li>provide access or links to any material (including links to peer to peer network “trackers”) which may infringe the intellectual property rights of another person; or</li>
          <li>delete or alter or attempt to delete or alter attributions, legal notices, trademarks or copyright marks on any material contained in the Website; and</li>
          <li>Compeat Nutrition reserves the right at all times to monitor your usage of its website and to retain any information as necessary to satisfy any applicable law, regulation, legal process or governmental request.</li>
        </ol>
        <ul>
          <li>You are required to nominate a username and password, which must be used by a single individual and is not transferable. It is your responsibility to ensure that these remain confidential at all times. If you become aware of any unauthorised use of your username or password, you must notify Compeat Nutrition immediately by email to <a href="mailto:admin@compeatnutrition.com.au">admin@compeatnutrition.com.au</a>.</li>
          <li>Business or Individuals who intend to or use Compeat Nutrition services for business purposes are required to confirm their identity within 3 days of first registration as a user.</li>
        </ul>
        <p><strong>10.&nbsp; Payment and refunds</strong></p>
        <p>You acknowledge and agree that membership is non-refundable at the time that payment is made. If you are dissatisfied with the Compeat Nutrition of the Compeat Nutrition Services and choose to discontinue using the Compeat Nutrition Site, membership fees or other payments that you have made will be refundable based on a 50% pro-rata basis.</p>
        <p>You agree that Compeat Nutrition will not be held liable for any loss you incur arising from your use of any payment method unless caused by our fraud or the fraud of our employees.</p>
        <p>Our subscription fee and other charges are GST exclusive. Compeat Nutrition as a health services provider is exempt from collecting GST. The receipt of payment issued will be a tax invoice in accordance with applicable legislative requirements relating to GST.</p>
        <p>All fees are non-refundable and are non-transferable except for:</p>
        <ol>
          <li>During the period in which you have paid for access to our information service through this Website, the information service is not available for a period lasting more than 3 days. In this circumstance, a pro rata refund may be granted upon written request by email at <a href="mailto:admin@compeatnutrition.com">admin@compeatnutrition.com</a>, however you acknowledge that we may refuse a request where we are able to provide information service to you through alternative means (such as email, social media etc.); or</li>
        </ol>
        <ul>
          <li>We agree to issue you with a refund in accordance with the clause (variation) below.</li>
        </ul>
        <p>To the extent permitted by law, you must bear any expenses that you may incur in connection with your request for such a refund.</p>
        <p>Upon receipt of a refund, your agreement with us is at an end and we will cancel your account. You agree that this is your sole remedy in these circumstances, other than any rights that may be available to you under the Australian Consumer Law.</p>
        <p><a href="https://client.compeatnutrition.com/refunds" target="_blank" rel="noopener noreferrer">Refund Policy</a></p>
        <p><strong>11.&nbsp; Membership cancellation</strong></p>
        <p>Users may cancel their membership at any point in time. Refunds will be complete in accordance with the refund policy.</p>
        <p><strong>12.&nbsp; Liability</strong></p>
        <p>Compeat Nutrition expressly excludes all conditions and warranties that may be implied by law. To the extent permitted by law, Compeat Nutrition’s liability for breach of any warranty or condition (implied or otherwise) which cannot be excluded is restricted to either the re-supply of the relevant Compeat Nutrition service or a refund in accordance with Compeat Nutrition’s refund policy (Clause 10).</p>
        <p>To the extent permitted by law, our total liability in respect of all claims in connection with this agreement (whether based in negligence or any other tort, contract, statutory liability or otherwise) will be the total sum of all fees paid or payable by you under this agreement up until and including the date the cause of action accrued.</p>
        <p>As a user of the Compeat Nutrition website, you acknowledge and agree that there are no circumstances whereby Compeat Nutrition will be liable to you for any indirect, incidental, special and/or consequential losses, damages, or loss of profits of any nature arising (including but not limited to any act or omission by Compeat Nutrition) which result from:</p>
        <ol>
          <li>Use or access or any inability to use or access the Compeat Nutrition Site or any material on the Site; or</li>
          <li>Unauthorised access to your user account or internet transmissions or data; or</li>
        </ol>
        <ul>
          <li>Statements or conduct by any third party on the Compeat Nutrition website; or</li>
        </ul>
        <ol>
          <li>Actions or decisions by you made in reliance on the information on or provided to you through the Compeat Nutrition website.</li>
          <li>Any interruption, delay or impairment in the functioning, operation or availability of the Compeat Nutrition website, exposure to or transmission of any computer virus, difficulties in connection with the Compeat Nutrition website, or malfunction in equipment or software.</li>
        </ol>
        <p>The limitation of liability set out in these terms and conditions does not attempt or purport to exclude liability arising under statute if, and to the extent, such liability cannot be lawfully excluded.</p>
        <p><strong>13.&nbsp; Indemnity</strong></p>
        <p>You agree to indemnify and release forever Compeat Nutrition and its directors, employees, agents, contractors and affiliates from and against any claims, demands, proceedings, losses and damages of any kind and nature, including reasonable legal fees, made by you or any third party due to or arising, whether directly or indirectly, out of your breach of the General Conditions of Use, or your violation of any law, or the rights of a third party.</p>
        <p><strong>14.&nbsp; Breach of General Conditions of Use</strong></p>
        <p>In situations of breach of the General Conditions of Use, Compeat Nutrition retains absolute discretion to immediately cancel or suspend a membership without notice. Without limiting other remedies available, we may, at our absolute discretion, immediately issue a warning, temporarily suspend or terminate your membership and refuse to provide services to you.</p>
        <p>Compeat Nutrition reserves the right to recover what is legally deemed as appropriate compensation for the breach of these general conditions of use.</p>
        <p><strong>15.&nbsp; Customer Complaints and Dispute resolution</strong></p>
        <p>Compeat Nutrition will attempt to resolve any customer complaints and answer any enquiries within 5 business days. Complaints or enquiries should be directed via email to admin@compeatnutrition.com.</p>
        <p>All complaints are reviewed by Compeat Nutrition customer support representatives and may be referred to a manager for resolution if required.</p>
        <p>Compeat Nutrition reserves the right not to respond to complaints or enquiries that offend Compeat Nutrition’s employees or agents. Compeat Nutrition also reserves the right not to respond to complaints or enquiries made by individuals who are not current users of, or have been suspended from of the Compeat Nutrition website.</p>
        <p>Compeat Nutrition will use reasonable endeavours to mediate any dispute concerning the use by parties of the Compeat Nutrition site and provided services. Disputes in relation to the actual services carried out by a business or any other issue will be referred, where appropriate, to external dispute resolution services or authorities.</p>
        <p><strong>16.&nbsp; Miscellaneous provisions</strong></p>
        <p>Compeat Nutrition may choose to run promotions or competitions, whereby prizes or other gifts may be awarded. Compeat Nutrition reserves the right to implement special terms and conditions for promotions or competitions. At the relevant time, those special terms and conditions will be published on the Compeat Nutrition website.</p>
        <p>If any clause of this agreement is found to be invalid, that clause shall be severed from this agreement and the remainder of this agreement will continue to be valid and enforceable.</p>
        <p>The laws governing this agreement will be the laws in NSW, Australia and you irrevocably submit to the exclusive jurisdiction of its Courts and the Appellate Courts and the jurisdiction of the Federal Court of Australia sitting in New South Wales</p>
        <p>We may suspend, limit or terminate your access to the Website (at our discretion) if we reasonably suspect, based on the results of such monitoring, that you are in breach of these terms.</p>
        <p><strong>17.&nbsp; Variation</strong></p>
        <p>You are bound by the latest version of the applicable Terms and Conditions. We may vary, amend or add to these terms at any time. Where there are material changes to these Terms and Conditions that could adversely affect you or Compeat Nutrition members. Compeat Nutrition will provide you additional notice to you by way of the email address you have provided to us. Without limiting our right to give notice by any other means, you will be deemed to have:</p>
        <ol>
          <li>received notice of such changes when we place a notice on the Website setting out the changes; and</li>
          <li>agreed to the terms and conditions as varied the next time you access your account after we publish the notice on the Website.</li>
        </ol>
        <p>If you do not agree with the changes, then prior to you accessing your account you must notify us by emailing <a href="mailto:admin@compeatnutrition.com">admin@compeatnutrition.com</a> and requesting a pro-rata refund. In the event any proposed changes have more than a minor detrimental effect on your circumstances (in our reasonable opinion), we will refund a percentage of your subscription fee calculated by reference to the amount of time remaining on your subscription.</p>
      </div>
    </PublicLayout>
  );
};

export default TermsView;