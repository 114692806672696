import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Setup from "./pages/authentication/Setup";
import AppleConsentPage from "./pages/authentication/AppleConsentPage";
import SignInView from './pages/authentication/Signin';
import AppOpenHandler from './context/AppOpenHandler';
import WelcomeView from './pages/Welcome';
import { Capacitor } from '@capacitor/core';
import EmailConfirmation from './pages/authentication/EmailConfirmation';
import TermsView from './views/Shared/Terms';
import PrivacyPolicyView from './views/Shared/Privacy';
import RefundPolicyView from './views/Shared/Refund';
import ProcessAction from './pages/authentication/ProcessAction';
import UnauthenticatedForms from './pages/Insights/UnauthenticatedForms';
import FormEmbed from './pages/Insights/FormEmbed';

const UnauthenticatedApp: React.FC = () => {
  return (
    <>
      <AppOpenHandler />
      <Switch>
        <Route path="/join/:coupon" exact><SignInView socialButtonActionLabel={'Continue'} hideGetStartedFree={true} /></Route>

        <Route path="/setup" exact><Setup /></Route>
        <Route path="/consent" exact><AppleConsentPage /></Route>
        <Route path="/signin" exact><SignInView /></Route>
        <Route path="/email-confirm" exact><EmailConfirmation /></Route>
        <Route path="/__/auth/action"><ProcessAction /></Route>
        <Route path="/insights/assessment/:assessmentId/forms" exact><UnauthenticatedForms /></Route>
        <Route path="/insights/assessment/:assessmentId/form/:typeformId" exact><FormEmbed /></Route>
        <Route path="/invite" exact><SignInView /></Route>
        <Route path="/welcome" exact><WelcomeView /></Route>

        <Route path="/terms" exact><TermsView /></Route>
        <Route path="/privacy" exact><PrivacyPolicyView /></Route>
        <Route path="/refunds" exact><RefundPolicyView /></Route>

        <Route path="*">
          {Capacitor.isNative ?
            <Redirect to="/welcome" /> :
            <Redirect to="/signin" />
          }
        </Route>
      </Switch>
    </>
  );
};

export default UnauthenticatedApp;
