import React, { useState, useEffect } from 'react';
import { IonToolbar, IonButtons, IonHeader, IonTitle, IonBackButton } from '@ionic/react';
import { Network } from '@capacitor/network';
import { SlideDown } from 'react-slidedown';
import { NotificationsButton } from './Notifications';
import fork from '../assets/compeat/fork_transparent.png';
import { arrowBackOutline } from 'ionicons/icons';
import { useModal } from '../context/ModalProvider';
import { useUser } from '../context/UserProvider';
import { useProfile } from '../context/ProfileProvider';
import 'react-slidedown/lib/slidedown.css';
import './Header.scss';

export interface BannerProps {
  text: string;
  color: string;
}

const Header = (props: { banner?: BannerProps | null; buttons?: any; children?: any; showBackButton?: boolean; }) => {
  const [connected, setConnected] = useState(true);
  const { setShowNotification } = useModal();
  const { user } = useUser();
  const { profile } = useProfile();

  useEffect(() => {
    Network.getStatus().then((status: { connected: React.SetStateAction<boolean>; }) => {
      setConnected(status.connected);
    });
    const handler = Network.addListener('networkStatusChange', (status: { connected: React.SetStateAction<boolean>; }) => {
      setConnected(status.connected);
    });
    return function cleanup() {
      handler.remove();
    };
  }, []);

  return (
    <IonHeader>
      <IonToolbar className="dashboard-toolbar">
        {props.showBackButton &&
          <IonButtons slot="start">
            <IonBackButton
              text=""
              icon={arrowBackOutline}
              className='px-2 back-button'
            />
          </IonButtons>
        }
        {props.children || <IonTitle>
          <img
            src={fork}
            width="44"
            height="44"
            alt="Compeat fork"
          />
        </IonTitle>}
        <IonButtons slot="primary">
          {props.buttons || (user?.id === profile?.id && <NotificationsButton handleOpen={() => setShowNotification(true)} />)}
        </IonButtons>
      </IonToolbar>
      <SlideDown>
        {!connected && <div className={`text-center bg-dark text-white`}>No Internet Connection</div>}
        {props.banner && <div className={`text-center bg-${props.banner.color} text-white`}>{props.banner.text}</div>}
      </SlideDown>
    </IonHeader>
  );
};

export default Header;

