import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import * as Sentry from '@sentry/react';
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api";
import { getUser } from '../graphql/queries';
import { useAuth } from './AuthProvider';
import { createContactInfo, createUser } from '../graphql/mutations';
import { onUpdateUser } from '../graphql/subscriptions';
import { isEqual, isNull, mergeWith } from 'lodash';
import { GetUserQuery, User, OnUpdateUserSubscription } from '../API';
import axios from 'axios';

interface UserContextInterface {
  user: User | undefined;
  setUser: Dispatch<SetStateAction<User | undefined>>;
}

const UserContext = React.createContext<UserContextInterface | null>(null);

const UserProvider: React.FC = (props) => {
  const { authUser } = useAuth();
  const [user, setUser] = useState<User | undefined>();

  useEffect(() => {
    if (authUser) {
      authUser.getIdTokenResult(true).then((idTokenResult) => {
        const compeatUserId = idTokenResult.claims && idTokenResult.claims.compeat_user_id ? idTokenResult.claims.compeat_user_id : authUser.uid;
        (API.graphql(graphqlOperation(getUser, { id: compeatUserId })) as Promise<GraphQLResult<GetUserQuery>>).then((result) => {
          if (result.data?.getUser) {
            // console.log('result.data.getUser', result.data.getUser);
            setUser(result.data.getUser ? result.data.getUser as User : undefined);
          } else {
            // create profile
            const names = authUser.displayName?.split(' ');
            const firstName = names?.shift();
            const lastNames = names?.join(' ');

            let avatar: string | null;
            // Check user image can be accessed
            axios.get(authUser.photoURL || '').then(() => {
              avatar = authUser.photoURL;
            }).catch(() => {
              avatar = null;
            }).finally(() => {
              const userParams = {
                id: authUser.uid,
                contactInfoId: authUser.uid,
                status: 'pending',
                firstName: firstName || ' ',
                lastName: lastNames || ' ',
                email: authUser.email || ' ',
                permission: { hasMeals: true },
              };
              const contactInfoParams = {
                id: authUser.uid,
                userId: authUser.uid,
                firstName: firstName,
                lastName: lastNames,
                avatar: avatar,
                email: authUser.email
              };

              (API.graphql(graphqlOperation(createUser, { input: userParams })) as Promise<any>).then((userResult) => {
                (API.graphql(graphqlOperation(createContactInfo, { input: contactInfoParams })) as Promise<any>).then((contactInfoResult) => {
                  setUser({ ...userResult.data.createUser, contactInfo: contactInfoResult.data.createContactInfo });
                });
              });
            });
          }

        }).catch((reason) => {
          console.log('reason', reason);
          Sentry.captureException(reason);
        });
      });
    } else {
      setUser(undefined);
    }
  }, [authUser]);

  useEffect(() => {
    if (user?.id) {
      const subscription = (API.graphql(graphqlOperation(onUpdateUser, { id: user.id })) as Observable<{ value: GraphQLResult<OnUpdateUserSubscription> }>).subscribe({
        next: ({ value }: { value: GraphQLResult<OnUpdateUserSubscription> }) => {
          const updateUser = value.data?.onUpdateUser;
          let shouldRefresh = false;
          // remove the connection as they aren't returned
          // and the attributes that aren't changed by the system
          // so that we don't re-render for no reason
          const excludedFields = [
            'devices',
            'messages',
            'specialists',
            'contactInfo',
            'clientProfile',
            'updatedAt',
            'lastActiveAt',
            'stripeCustomerId',
            'paymentMethod'
          ];
          const userValues = user as Record<string, unknown>;
          const updateUserValues = updateUser as Record<string, unknown>;
          for (const key in updateUser) {
            if (!excludedFields.includes(key) && !isEqual(updateUserValues[`${key}`], userValues[`${key}`])) {
              shouldRefresh = true;
            }
          }

          if (shouldRefresh) {
            const _user = mergeWith({}, user, updateUser, (o, s) => {
              // This logic is here to keep any existing connections on the user.
              // That is because the publishUser mutation in the API does not have the connections.
              // It is assumed that connections other than contactInfo have items.
              // FirstName is looked for as it is in contactInfo
              if (isNull(s) && (o?.hasOwnProperty('items') || (o && Object.keys(o).includes('firstName')))) return o;
              return s;
            });
            console.log('_user', _user);
            subscription.unsubscribe();  // Close subscription as another will be opened when user set.
            setUser(_user);
          }
        },
        error: (error) => {
          Sentry.captureException(error);
        }
      });
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser }} {...props} />
  );
};

const useUser = () => {
  const context = React.useContext(UserContext) as UserContextInterface;
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }
  return context;
};

export { useUser };
export default UserProvider;
