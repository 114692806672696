// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
import { faCalendarPlus } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(
  faCalendarPlus,
  fas
  // more icons go here
);
