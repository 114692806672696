import React from 'react';
import PublicLayout from '../../layouts/Public';

const RefundPolicyView = () => {
  return (
    <PublicLayout>
      <div className="p-4">
        <h1>Refund Policy</h1>
        <p>We are not required to provide a refund if you change your mind about the services you asked for.</p>
        <p>But you can choose to cancel your contract, and receive a refund for unconsumed services, if the service has a <strong>major</strong> problem.  This is when the service:</p>
        <ul>
          <li>has a problem that would have stopped someone from purchasing the service if they had known about it</li>
          <li>is substantially unfit for it's common purpose, and can't be easily fixed within a reasonable time</li>
          <li>does not meet the specific purpose you asked for and cannot be easily rectified within a reasonable time</li>
          <li>creates an unsafe situation.</li>
        </ul>
        <p>If you choose to continue with the contract, you can ask us to compensate you for any difference in the value of the services we provided and what you paid.</p>
        <p>If the problem is <strong>not major</strong>, we will fix it within a reasonable time.  If the problem cannot be fixed, we view it as a major problem.</p>

        <p>Last update: 17 May 2022</p>
      </div>
    </PublicLayout>
  );
};

export default RefundPolicyView;