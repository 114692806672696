import { Browser } from "@capacitor/browser";
import { IonFab, IonFabButton, IonIcon } from '@ionic/react';
import { helpOutline } from 'ionicons/icons';
import React from 'react';

const SupportFAB = () => {

  const handleClick = async () => {
    await Browser.open({
      url: process.env.REACT_APP_SUPPORT_URL || 'https://share.hsforms.com/1yUGhKceXQSSwSsPjuMOx1A4k30c'
    });
  };
  return (
    <IonFab vertical="bottom" horizontal="end" slot="fixed">
      <IonFabButton onClick={() => handleClick()}>
        <IonIcon icon={helpOutline} size="large" />
      </IonFabButton>
    </IonFab>

  );
};

export default SupportFAB;