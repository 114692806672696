import { IonSlide, IonIcon, IonAlert } from "@ionic/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { logoApple } from "ionicons/icons";
import { useAuth } from '../../context/AuthProvider';

const buttonIconStyle = {
  height: '100px',
  width: '100px'
};

const AppleConsentPage = () => {
  const { socialLogin, error } = useAuth();
  const history = useHistory();

  return (
    <IonSlide className="bg-white">
      {error ?
        <IonAlert
          header={'Oh Nutmeg!'}
          message={error}
          isOpen={true}
          buttons={['OK']}
        />
        : null}
      <div className="h-100 w-100 d-flex flex-column justify-content-center text-left px-3">
        <div className="d-flex justify-content-center align-items-center pb-5">
          <IonIcon
            slot="start"
            style={buttonIconStyle}
            icon={logoApple}
            color="dark"
          />
        </div>
        <div>
          <h4 className="text-center">Allow "Compeat" to link your Apple ID to your registered
            email and provided personal information?</h4>
          <div className="pt-2">
            <p className="text-center">Linking your Apple ID to your personal information is required to use Sign in
              with Apple.</p>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center pt-3">
          <div className="row">
            <div className="col d-flex flex-row-reverse">
              <button
                className="btn btn-primary ml-2"
                onClick={() => socialLogin("apple.com")}
              >
                Allow
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => history.push('/')}
              >
                Don't Allow
              </button>
            </div>
          </div>
        </div>
      </div>
    </IonSlide>
  );
};

export default AppleConsentPage;
