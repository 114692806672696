/* tslint:disable */
/* eslint-disable */

export const onCreateAlert = /* GraphQL */ `
  subscription OnCreateAlert($alertUserId: ID!) {
    onCreateAlert(alertUserId: $alertUserId) {
      id
      content
      action
      isRead
      createdAt
      updatedAt
      alertUserId
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage($messageConversationId: ID!) {
    onCreateMessage(messageConversationId: $messageConversationId) {
      id
      content
      createdAt
      updatedAt
      readBy
      messageAuthorId
      messageConversationId
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage($messageConversationId: ID!) {
    onUpdateMessage(messageConversationId: $messageConversationId) {
      id
      content
      createdAt
      updatedAt
      readBy
      messageAuthorId
      messageConversationId
    }
  }
`;
export const onUpdateConversation = /* GraphQL */ `
  subscription OnUpdateConversation($id: ID!) {
    onUpdateConversation(id: $id) {
      id
      clientId
      specialistId
      servicingNote
      messages {
        items {
          id
          content
          createdAt
          readBy
          messageConversationId
          updatedAt
        }
        nextToken
      }
      files {
        title
        url
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($id: ID!) {
    onUpdateUser(id: $id) {
      id
      authId
      email
      firstName
      lastName
      profile {
        sex
        pronouns
        ageBracket
      }
      gender
      bodyType
      height
      weight
      bodyFat
      activityFactor
      nutritionNotes
      joinReason
      trainingCalendarLink
      guessedLocation
      journal {
        weight
        waist
        appetiteRating
        energyRating
        sleepRating
        motivationRating
        wellnessRating
        stressRating
        nutritionRating
        createdAt
      }
      events {
        name
        details
        date
        location
        goal
        preEventFoodRituals
        nutritionProducts
        nutritionLogistics
        issues
        leadupEvents
        accomodation
        leadupEventDate
        leadupEventDetails
        createdAt
      }
      sports {
        name
        level
        comments
      }
      goals {
        description
        comments
        progress {
          rating
          createdAt
        }
        targetDate
        createdAt
      }
      teams {
        name
        joinedAt
      }
      foodDiary {
        date
        day
        items {
          time
          detail
          comments
        }
      }
      nutritionInformation {
        allergies
        supplements
        dislikes
        comments
        preferences
        goals {
          description
          comments
          progress {
            rating
            createdAt
          }
          targetDate
          createdAt
        }
        dietaryRequirements
      }
      nutritionAssessments {
        requestDate
        responseStartDate
        responseCompleteDate
        responses {
          question
          answer
          comments
          score
        }
        score
      }
      lifestyle {
        cookingSkills
        cookingMethods
        easyNights
        homeSituation
        peopleToFeed
        workDays
        nightsEatingOut
        workShifts
        breakfastOnTheRun
        occupation
        preferences
      }
      healthInformation {
        medicalConditions
        medication
        isMenstruating
        regularPeriod
        periodStoppedOver3Months
      }
      membership {
        id
        amount
        plan
        discount
        createdAt
        expiresAt
        cancelAtPeriodEnd
      }
      stripeCustomerId
      permission {
        hasMeals
        hasHabits
      }
      menuScheduleDay
      priority {
        bonking
        energyLevels
        gutUpset
        manageCramping
        muscleMass
        overallHealth
        performance
        raceDayNutrition
        trainingAdaptations
        weightLoss
        createdAt
      }
      priorityConfidence {
        bonking
        energyLevels
        gutUpset
        manageCramping
        muscleMass
        overallHealth
        performance
        raceDayNutrition
        trainingAdaptations
        weightLoss
        createdAt
      }
      lastActiveAt
      createdAt
      updatedAt
      paymentMethod {
        id
        type
        brand
        exp_month
        exp_year
        last4
      }
      status
      devices {
        items {
          id
          token
          platform
          bundleId
          deviceArn
          deviceUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          content
          createdAt
          messageAuthorId
          readBy
          messageConversationId
          updatedAt
        }
        nextToken
      }
      specialists {
        items {
          id
          clientId
          specialistId
          specialist {
            id
            displayName
            businessName
            qualifications
            clinicalSpecialities
            sportSpecialities
            bio
            trivia
            providerNumber
            businessIdNumber
            gender
            image
            video
            status
            isAvailable
            clientLimit
            ownerId
            createdAt
            updatedAt
          }
          messages {
            items {
              id
              content
              createdAt
              messageAuthorId
              readBy
              messageConversationId
              updatedAt
            }
            nextToken
          }
          status
          servicingNote
          files {
            title
            url
            type
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      editors
      viewers
      contactInfoId
      contactInfo {
        id
        userId
        firstName
        lastName
        avatar
        email
        phone
        permissionToShare  
        createdAt
        updatedAt
      }
      clientProfile {
        items {
          id
          userId
          specialistId
          status
          servicingNote
          settings
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateContactInfo = /* GraphQL */ `
  subscription OnUpdateContactInfo($id: ID!) {
    onUpdateContactInfo(id: $id) {
      id
      userId
      firstName
      lastName
      avatar
      email
      phone
      permissionToShare
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateHabits = /* GraphQL */ `
  subscription onUpdateHabits($id: ID!) {
    onUpdateHabits(id: $id) {
      id
      identityGoal
      weeklyReflectionDay
      log {
        content
        type
        createdAt
      }
      behaviours {
        id
        behaviour
        status
        colour
      }
      actions {
        id
        description
        status
        behaviour
        behaviourId
      }
      status
      userId
      editors
      viewers
      createdAt
      updatedAt
    }
  }
`;
export const onCreateHabitsByUser = /* GraphQL */ `
  subscription OnCreateHabitsByUser($userId: ID!) {
    onCreateHabitsByUser(userId: $userId) {
      id
      identityGoal
      weeklyReflectionDay
      log {
        content
        type
        createdAt
      }
      behaviours {
        id
        behaviour
        status
        colour
      }
      actions {
        id
        description
        status
        behaviour
        behaviourId
      }
      status
      userId
      editors
      viewers
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMealPlan = /* GraphQL */ `
  subscription OnCreateMealPlan($userId: ID!) {
    onCreateMealPlan(userId: $userId) {
      id
      type
      kilojoules
      mealGroups {
        type
        count
      }
      meals {
        items {
          id
          mealPlanId
          mealId
          meal {
            id
            author
            source {
              name
              avatar
              url
            }
            title
            image
            description
            mealTypes
            dietary
            allergens
            cuisines
            tags
            cookingMethods
            cookingSkill
            ingredients {
              items {
                id
                mealId
                foodId
                food {
                  id
                  item
                  measurementUnits
                  unit
                  aisle
                  allergens
                  density
                  createdAt
                  updatedAt
                }
                item
                qty
                unit
                isOptional
                createdAt
                updatedAt
              }
              nextToken
            }
            serves
            instructions {
              step
              direction
            }
            times {
              total
              prep
              cook
            }
            nutrition {
              kilojoules
              carbohydrates
              fat
              protein
              fibre
              iron
              calcium
            }
            notes
            status
            showInRecipe
            updatedAt
            createdAt
          }
          type
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      startingAt
      status
      userId
      mealPlanSetId
      editors
      viewers
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMealPlan = /* GraphQL */ `
  subscription OnUpdateMealPlan($userId: ID!) {
    onUpdateMealPlan(userId: $userId) {
      id
      type
      kilojoules
      mealGroups {
        type
        count
      }
      meals {
        items {
          id
          mealPlanId
          mealId
          meal {
            id
            author
            source {
              name
              avatar
              url
            }
            title
            image
            description
            mealTypes
            dietary
            allergens
            cuisines
            tags
            cookingMethods
            cookingSkill
            ingredients {
              items {
                id
                mealId
                foodId
                food {
                  id
                  item
                  measurementUnits
                  unit
                  aisle
                  allergens
                  density
                  createdAt
                  updatedAt
                }
                item
                qty
                unit
                isOptional
                createdAt
                updatedAt
              }
              nextToken
            }
            serves
            instructions {
              step
              direction
            }
            times {
              total
              prep
              cook
            }
            nutrition {
              kilojoules
              carbohydrates
              fat
              protein
              fibre
              iron
              calcium
            }
            notes
            status
            showInRecipe
            updatedAt
            createdAt
          }
          type
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      startingAt
      status
      userId
      mealPlanSetId
      editors
      viewers
      createdAt
      updatedAt
    }
  }
`;