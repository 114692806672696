import React, { ReactNode } from 'react';
import { IonContent, IonPage } from '@ionic/react';

const PublicLayout = (props: { children: ReactNode; }) => {
  const { children } = props;
  return (
    <IonPage className="authentication">
      <IonContent className="bg-sign-in">
        <div className="container bg-white min-vh-100">
          <div className="app-navbar-notch">&nbsp;</div>
          {children}
          <div className="app-navbar-notch">&nbsp;</div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PublicLayout;