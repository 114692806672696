import React from 'react';
import { useQuery } from '../../helpers/useQuery';
import LoginForm from "../../components/LoginForm";
import { useHistory } from "react-router-dom";

const Setup = () => {
  const history = useHistory();
  const resetToken = useQuery().get('token');
  if (resetToken) {
    localStorage.setItem('resetToken', resetToken);
  } else {
    history.push('/');
  }

  return (
    <LoginForm />
  );
};

export default Setup;
