import React, { ReactNode } from 'react';
import { IonPage, IonTitle, IonContent } from '@ionic/react';
import Header from '../components/Header';
import SupportFAB from '../components/SupportFAB';

const DashboardLayout = (props: { children?: ReactNode | ReactNode[]; title?: string; showBackButton?: boolean; buttons?: ReactNode; hideSupport?: boolean; scrollYDisabled?: boolean; }) => {
  const { children, title, showBackButton, buttons, hideSupport, scrollYDisabled } = props;

  return (
    <IonPage>
      <Header showBackButton={showBackButton} buttons={buttons}>
        {title && <IonTitle>{title}</IonTitle>}
      </Header>
      <IonContent scrollY={!scrollYDisabled}>
        {!hideSupport && <SupportFAB />}
        <div className="h-100 container-fluid p-0">
          <div className="h-100 row no-gutters d-flex justify-content-center">
            <div className="col-12 col-lg-9 col-xxl-7">
              <div className="h-100 shadow-sm bg-white">
                {children}
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default DashboardLayout;