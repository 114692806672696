import React from 'react';
import { Redirect } from 'react-router-dom';
import { useQuery } from '../../helpers/useQuery';
import VerifyEmail from './VerifyEmail';
import PasswordReset from './PasswordReset';
import SignInView from './Signin';

const ProcessAction = (props: { authenticated?: boolean; }) => {
  const { authenticated } = props;
  const mode = useQuery().get('mode') || '';

  return (
    <>
      {authenticated ?
        mode === "verifyEmail" ?
          <VerifyEmail />
          :
          <Redirect to="/meals" />
        :
        mode === "resetPassword" ?
          <PasswordReset />
          :
          <SignInView />
      }
    </>
  );
};

export default ProcessAction;
