import React, { useEffect, useState } from 'react';
import { Preferences } from '@capacitor/preferences';
import { Network } from '@capacitor/network';
import { OfflineStorage } from '../declarations/declarations';

interface OfflineStorageInterface {
  isConnected: boolean;
  get: (key: string) => Promise<OfflineStorage>;
  set: (key: string, data: OfflineStorage) => Promise<void>;
  getOffline: <T extends unknown>(key: string) => Promise<T | undefined>;
  setOffline: <T extends unknown>(key: string, data: T) => Promise<void>;
  remove: (key: string) => Promise<void>;
  getKeys: () => Promise<{ keys: string[] }>;
  clear: () => Promise<void>;
  list: (key: string, page: number, limit: number, filterPredicate: ((i: Record<string, unknown> | undefined) => boolean) | undefined, sortPredicate: ((a: Record<string, unknown> | undefined, b: Record<string, unknown> | undefined) => number) | undefined) => Promise<({ data: Record<string, unknown>[], isNextPage: boolean })>;
}

const OfflineStorageContext = React.createContext<any | null>(null);

const OfflineStorageProvider: React.FC = (props) => {
  const RESULTS_PER_PAGE = 25;
  const [isConnected, setIsConnected] = useState<boolean>(true);

  useEffect(() => {
    Network.getStatus().then((status) => {
      setIsConnected(status.connected);
    });
    const handler = Network.addListener('networkStatusChange', (status) => {
      setIsConnected(status.connected);
    });
    return function cleanup() {
      handler.remove();
    };
  }, []);

  const get = async (key: string): Promise<OfflineStorage> => {
    const { value } = await Preferences.get({ key });
    return value ? JSON.parse(value) : undefined;
  };

  const set = async (key: string, data: OfflineStorage): Promise<void> => {
    const value: string = JSON.stringify(data);
    await Preferences.set({ key, value });
  };

  const getOffline = async <T extends unknown>(key: string): Promise<T | undefined> => {
    const { value } = await Preferences.get({ key });
    return value ? JSON.parse(value) : undefined;
  };

  const setOffline = async <T extends unknown>(key: string, data: T) => {
    const value: string = JSON.stringify(data);
    await Preferences.set({ key, value });
  };

  const remove = async (key: string): Promise<void> => {
    await Preferences.remove({ key });
  };

  const getKeys = async (): Promise<{ keys: string[] }> => {
    return Preferences.keys();
  };

  const clear = async (): Promise<void> => {
    await Preferences.clear();
  };

  const list = async (key: string, page = 1, limit = 0, filterPredicate: ((i: Record<string, unknown> | undefined) => boolean) | undefined = undefined, sortPredicate: ((a: Record<string, unknown> | undefined, b: Record<string, unknown> | undefined) => number) | undefined = undefined): Promise<({ data: Record<string, unknown>[], isNextPage: boolean })> => {
    if (!key.length) {
      return { data: [], isNextPage: false };
    }

    let items = await get(key);
    if (!Array.isArray(items)) {
      return { data: [], isNextPage: false };
    }

    const minResult = (page - 1) * (limit || RESULTS_PER_PAGE);
    const maxResult = page * (limit || RESULTS_PER_PAGE);

    if (filterPredicate) {
      items = items.filter((i: Record<string, unknown> | undefined) => filterPredicate(i));
    }
    if (sortPredicate) {
      items = items.sort((a: Record<string, unknown> | undefined, b: Record<string, unknown> | undefined) => sortPredicate(a, b));
    }

    const data = items.slice(minResult, maxResult);
    return { data: data, isNextPage: !!data.length && data.length === (limit || RESULTS_PER_PAGE) };
  };

  return (
    <OfflineStorageContext.Provider value={{ isConnected, get, set, getOffline, setOffline, remove, getKeys, clear, list }} {...props} />
  );
};

const useOfflineStorage = () => {
  const context = React.useContext(OfflineStorageContext) as OfflineStorageInterface;
  if (context === undefined) {
    throw new Error(`useOfflineStorage must be used within a OfflineStorageProvider`);
  }
  return context;
};

export { useOfflineStorage };
export default OfflineStorageProvider;
