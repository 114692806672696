import React, { useEffect, useState } from 'react';
import API, { GraphQLResult } from '@aws-amplify/api';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql';
// import { useHistory } from 'react-router-dom';
import { Assessment, GetAssessmentQuery } from '../../declarations/awsTypeDeclarations';
import { getAssessmentUnauthenticated } from '../../graphql/queries';
import FormList from './FormList';
import UnauthenticatedLayout from '../../layouts/Unathenticated';
import { useOfflineStorage } from '../../context/OfflineStorageProvider';
import { useParams } from 'react-router-dom';

const UnauthenticatedForms = () => {
  const { assessmentId } = useParams<{ assessmentId: string; }>();
  const { getOffline, remove } = useOfflineStorage();
  // const history = useHistory();
  const [assessment, setAssessment] = useState<Assessment | null | undefined>();

  useEffect(() => {
    if (assessmentId && getOffline && remove) {
      const getData = async () => {
        const response = await API.graphql({
          query: getAssessmentUnauthenticated,
          variables: { id: assessmentId },
          authMode: GRAPHQL_AUTH_MODE.API_KEY
        }) as GraphQLResult<GetAssessmentQuery>;

        if (response.data?.getAssessment && !response.errors) {
          const result = response.data.getAssessment;
          const completedFormId = await getOffline<string | undefined>('completedFormId');
          if (completedFormId) {
            setAssessment({
              ...result,
              forms: result.forms.map((f) => (f?.typeformId === completedFormId ? { ...f, submittedAt: new Date().toISOString() } : f))
            });
            await remove('completedFormId');
          } else {
            setAssessment(result);
          }
        }
      };
      getData().then();
    }
  }, [assessmentId, getOffline, remove]);

  // useEffect(() => {
  //   if (assessment && formId && history) {
  //     if (formId) {
  //       const form = assessment.forms.find((f) => f?.typeformId === formId);
  //       console.log(form);
  //       if (form && !form.submittedAt) {
  //         history.push(`/insights/form/${form.typeformId}?&id=${assessment.userId}&assessmentId=${assessment.id}`);
  //       }
  //     }
  //   }
  // }, [assessment, formId, history]);

  return (
    <UnauthenticatedLayout title={'Insights'}>
      <FormList assessment={assessment} />
    </UnauthenticatedLayout>
  );
};

export default UnauthenticatedForms;