import React, { Suspense, useEffect } from 'react';
import { IonApp, isPlatform, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Amplify from 'aws-amplify';
import Loading from './components/Loading';
import { withOAuth } from 'aws-amplify-react';
import firebase from "firebase/compat/app";
import firebaseConfig from './firebase-config';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './App.scss';

import UnauthenticatedApp from './UnauthenticatedApp';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import AlertProvider from './context/AlertProvider';
import { useAuth } from './context/AuthProvider';
import OfflineStorageProvider from './context/OfflineStorageProvider';
import mixpanel from 'mixpanel-browser';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Preferences } from "@capacitor/preferences";

const loadAuthenticatedApp = () => import('./AuthenticatedApp');
const AuthenticatedApp = React.lazy(loadAuthenticatedApp);
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK || '');

firebase.initializeApp(firebaseConfig);
const auth = firebase?.auth();

const appSyncConfig = {
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_GRAPHQLENDPOINT,
  aws_appsync_apiKey: process.env.REACT_APP_APPSYNC_API_KEY,
  aws_appsync_region: "ap-southeast-2",
  aws_appsync_authenticationType: "OPENID_CONNECT",
  API: {
    endpoints: [
      {
        name: "REST_API",
        endpoint: process.env.REACT_APP_REST_API,
        custom_header: async () => {
          return { Authorization: await auth.currentUser?.getIdToken() };
        }
      }
    ],
    graphql_headers: async () => ({
      Authorization: await auth.currentUser?.getIdToken()
    })
  }
};
Amplify.configure(appSyncConfig);

console.log('%cThis is the Compeat Client app', 'font-size: 12px; color: #fff; background-color: #1ab1ac; padding: 2px 4px;');
console.log('%cProudly made in Newcastle 🇦🇺 ', 'font-size: 12px; color: #1ab1ac');

setupIonicReact({
  animated: !isPlatform('desktop'),
  backButtonText: ''
});

// https://material.io/resources/color/#!/?view.left=0&view.right=1&primary.color=B71C1C
const theme = createTheme({
  palette: {
    primary: {
      main: '#1ab1ac',
      contrastText: '#000',
    },
    secondary: {
      main: '#0a1545',
      contrastText: '#fff',
    },
    tertiary: {
      main: '#ab1ad1',
      contrastText: '#fff',
    }
  }
});

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    tertiary?: PaletteOptions['primary'];
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

const App: React.FC = () => {
  const { authUser, loading } = useAuth();

  useEffect(() => {
    if (process.env.REACT_APP_MIXPANEL_KEY) mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY);
  }, []);

  // Migrate data from Capacitor 2 Storage plugin
  Preferences.migrate().then(() => Preferences.removeOld());

  loadAuthenticatedApp();

  const renderLoader = () => <Loading />;
  if (loading) return renderLoader();
  return (

    <Elements stripe={stripePromise}>
      <IonApp>
        <ThemeProvider theme={theme}>
          <OfflineStorageProvider>
            <Suspense fallback={renderLoader()}>
              <IonReactRouter>
                {authUser ?
                  <AlertProvider>
                    <AuthenticatedApp />
                  </AlertProvider>
                  :
                  <UnauthenticatedApp />
                }
              </IonReactRouter>
            </Suspense>
          </OfflineStorageProvider>
        </ThemeProvider>
      </IonApp>
    </Elements>
  );
};

export default withOAuth(App);
