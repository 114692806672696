import { App } from '@capacitor/app';
import { useHistory } from 'react-router-dom';

const AppOpenHandler = () => {
  const history = useHistory();

  const handleRouteChange = (path: string) => {
    console.log('handleRouteChange', path);
    history.push(path);
  };

  App.addListener('appUrlOpen', (data) => {
    // Remove host part of url
    const path = data.url.replace(/^.*\/\/[^/]+/, '');
    handleRouteChange(path);
  });

  return null;
};

export default AppOpenHandler;