import React, { useState, useEffect } from 'react';
import update from 'react-addons-update';
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api";
import { Alert } from '../API';
import { updateAlert } from '../graphql/mutations';
import { alertByUserByAction } from "../graphql/queries";
import { AlertByUserByActionQuery, UpdateAlertMutation } from '../declarations/awsTypeDeclarations';
import { useUser } from "./UserProvider";
import loadData from '../helpers/graphqlPagination';

interface AlertContextInterface {
  notifications: Alert[] | null | undefined;
  clearAlertsByPath: (alerts: Alert[] | null | undefined, path: string | RegExp) => void;
}

const AlertContext = React.createContext<AlertContextInterface | null>(null);

const AlertProvider: React.FC = (props) => {
  const { user } = useUser();
  const [notifications, setNotifications] = useState<Alert[] | null | undefined>([]);

  useEffect(() => {
    if (user?.id) {
      loadData<AlertByUserByActionQuery, Alert>('alertByUserByAction', alertByUserByAction, { alertUserId: user.id, actionCreatedAt: { beginsWith: { action: 'notification', createdAt: '20' } }, sortDirection: 'DESC', limit: 100 }).then((results) => {
        setNotifications(results.items);
      });
    }
  }, [user]);

  const clearAlertsByPath = (alerts: Alert[] | null | undefined, path: string | RegExp) => {
    const regex = new RegExp(path, "ig");
    const matchingAlerts = alerts?.filter((notification) => !notification.isRead && notification.content && !!notification.content.match(regex)) || [];
    const promises = matchingAlerts.map((alert) => {
      return API.graphql(graphqlOperation(updateAlert, { input: { ...alert, isRead: true } })) as Promise<GraphQLResult<UpdateAlertMutation>>;
    });
    Promise.all(promises).then((updatedAlerts) => {
      let originalAlerts = alerts || [];
      for (const updatedAlert of updatedAlerts) {
        const index = originalAlerts.findIndex((n) => n.id === updatedAlert.data?.updateAlert?.id);
        if (index > -1 && updatedAlert.data?.updateAlert) originalAlerts = update(originalAlerts, { $splice: [[index, 1, updatedAlert.data.updateAlert]] });
      }
      setNotifications(originalAlerts);
    }).catch((e) => console.log(e));
  };

  return (
    <AlertContext.Provider value={{ notifications, clearAlertsByPath }} {...props} />
  );
};

const useAlerts = () => {
  const context = React.useContext(AlertContext) as AlertContextInterface;
  if (context === undefined) {
    throw new Error(`useAlerts must be used within a AlertProvider`);
  }
  return context;
};

export { useAlerts };
export default AlertProvider;
