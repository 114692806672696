/* tslint:disable */
/* eslint-disable */

export const getConversation = /* GraphQL */ `
  query GetConversation($id: ID!, $nextMessagesToken: String, $messageLimit: Int) {
    getConversation(id: $id) {
      id
      specialistClientId
      clientId
      specialistId
      title
      specialist {
        id
        displayName
        businessName
        qualifications
        clinicalSpecialities
        sportSpecialities
        bio
        trivia
        providerNumber
        businessIdNumber
        gender
        image
        video
        status
        isAvailable
        clientLimit
        ownerId
        createdAt
        updatedAt
      }
      messages(limit: $messageLimit, nextToken: $nextMessagesToken) {
        items {
          id
          content
          createdAt
          messageAuthorId
          readBy
          messageConversationId
          updatedAt
        }
        nextToken
      }
      status
      servicingNote
      files {
        title
        url
        type
        createdAt
      }
      settings
      members
      createdAt
      updatedAt
    }
  }`;
export const listConversations = /* GraphQL */ `
  query ListConversations(
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConversations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        specialistClientId
        clientId
        specialistId
        title
        specialist {
          id
          displayName
          businessName
          qualifications
          clinicalSpecialities
          sportSpecialities
          bio
          trivia
          providerNumber
          businessIdNumber
          gender
          image
          video
          status
          isAvailable
          clientLimit
          ownerId
          createdAt
          updatedAt
        }
        messages(limit: 10, sortDirection: DESC) {
          items {
            id
            content
            createdAt
            messageAuthorId
            readBy
            messageConversationId
            updatedAt
          }
          nextToken
        }
        status
        servicingNote
        files {
          title
          url
          type
          createdAt
        }
        settings
        members
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listRecipes = /* GraphQL */ `
  query ListRecipes(
    $filter: ModelRecipeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecipes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        image
        recipeLink
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSpecialistProfile = /* GraphQL */ `
  query GetSpecialistProfile($id: ID!) {
    getSpecialistProfile(id: $id) {
      id
      displayName
      businessName
      qualifications
      clinicalSpecialities
      sportSpecialities
      bio
      trivia
      providerNumber
      businessIdNumber
      gender
      image
      video
      status
      isAvailable
      clientLimit
      socialAccounts {
        network
        account
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSpecialistProfiles = /* GraphQL */ `
  query ListSpecialistProfiles(
    $filter: ModelSpecialistProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpecialistProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        displayName
        businessName
        qualifications
        clinicalSpecialities
        sportSpecialities
        bio
        trivia
        providerNumber
        businessIdNumber
        gender
        image
        video
        status
        isAvailable
        clientLimit
        socialAccounts {
          network
          account
        }
        ownerId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      authId
      email
      firstName
      lastName
      profile {
        sex
        pronouns
        ageBracket
      }
      gender
      bodyType
      height
      weight
      bodyFat
      activityFactor
      nutritionNotes
      joinReason
      trainingCalendarLink
      guessedLocation
      journal {
        weight
        waist
        appetiteRating
        energyRating
        sleepRating
        motivationRating
        wellnessRating
        stressRating
        nutritionRating
        createdAt
      }
      events {
        name
        details
        date
        location
        goal
        preEventFoodRituals
        nutritionProducts
        nutritionLogistics
        issues
        leadupEvents
        accomodation
        leadupEventDate
        leadupEventDetails
        createdAt
      }
      sports {
        name
        level
        comments
      }
      goals {
        description
        comments
        progress {
          rating
          createdAt
        }
        targetDate
        createdAt
      }
      teams {
        name
        joinedAt
      }
      foodDiary {
        date
        day
        items {
          time
          detail
          comments
        }
      }
      nutritionInformation {
        allergies
        supplements
        dislikes
        comments
        preferences
        goals {
          description
          comments
          progress {
            rating
            createdAt
          }
          targetDate
          createdAt
        }
        dietaryRequirements
      }
      nutritionAssessments {
        requestDate
        responseStartDate
        responseCompleteDate
        responses {
          question
          answer
          comments
          score
        }
        score
      }
      lifestyle {
        cookingSkills
        cookingMethods
        easyNights
        homeSituation
        peopleToFeed
        workDays
        nightsEatingOut
        workShifts
        breakfastOnTheRun
        occupation
        preferences
      }
      healthInformation {
        medicalConditions
        medication
        isMenstruating
        regularPeriod
        periodStoppedOver3Months
      }
      membership {
        id
        amount
        plan
        discount
        createdAt
        expiresAt
        cancelAtPeriodEnd
      }
      stripeCustomerId
      permission {
        hasMeals
        hasHabits
      }
      menuScheduleDay
      priority {
        bonking
        energyLevels
        gutUpset
        manageCramping
        muscleMass
        overallHealth
        performance
        raceDayNutrition
        trainingAdaptations
        weightLoss
        createdAt
      }
      priorityConfidence {
        bonking
        energyLevels
        gutUpset
        manageCramping
        muscleMass
        overallHealth
        performance
        raceDayNutrition
        trainingAdaptations
        weightLoss
        createdAt
      }
      lastActiveAt
      createdAt
      updatedAt
      paymentMethod {
        id
        type
        brand
        exp_month
        exp_year
        last4
      }
      status
      devices {
        items {
          id
          token
          platform
          bundleId
          deviceArn
          deviceUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          content
          createdAt
          messageAuthorId
          readBy
          messageConversationId
          updatedAt
        }
        nextToken
      }
      specialists {
        items {
          id
          clientId
          specialistId
          specialist {
            id
            displayName
            businessName
            qualifications
            clinicalSpecialities
            sportSpecialities
            bio
            trivia
            providerNumber
            businessIdNumber
            gender
            image
            video
            status
            isAvailable
            clientLimit
            ownerId
            createdAt
            updatedAt
          }
          messages {
            items {
              id
              content
              createdAt
              messageAuthorId
              readBy
              messageConversationId
              updatedAt
            }
            nextToken
          }
          status
          servicingNote
          files {
            title
            url
            type
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      editors
      viewers
      contactInfoId
      contactInfo {
        id
        userId
        firstName
        lastName
        avatar
        email
        phone
        permissionToShare
        createdAt
        updatedAt
      }
      clientProfile {
        items {
          id
          userId
          specialistId
          status
          servicingNote
          settings
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
 query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contactInfo {
          id
          userId
          firstName
          lastName
          avatar
          email
          phone
          permissionToShare
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const alertByAction = /* GraphQL */ `
  query AlertByAction(
    $action: Action
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    alertByAction(
      action: $action
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        action
        isRead
        createdAt
        updatedAt
        alertUserId
      }
      nextToken
    }
  }
`;
export const alertByUserByAction = /* GraphQL */ `
  query AlertByUserByAction(
    $alertUserId: ID
    $actionCreatedAt: ModelAlertByUserByActionCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    alertByUserByAction(
      alertUserId: $alertUserId
      actionCreatedAt: $actionCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        action
        isRead
        createdAt
        updatedAt
        alertUserId
      }
      nextToken
    }
  }
`;
export const contactInfoByUser = /* GraphQL */ `
  query ContactInfoByUser(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelContactInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contactInfoByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        firstName
        lastName
        avatar
        email
        phone
        permissionToShare
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInvitation = /* GraphQL */ `
  query GetInvitation($id: ID!) {
    getInvitation(id: $id) {
      id
      clientId
      invitedUserId
      email
      contactType
      firstName
      lastName
      status
      createdAt
      updatedAt
    }
  }
`;
export const getUserHabits = /* GraphQL */ `
  query GetUserHabits(
    $userId: String
    $sortDirection: ModelSortDirection
    $filter: ModelHabitsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserHabits(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        identityGoal
        weeklyReflectionDay
        log {
          content
          type
          createdAt
        }
        behaviours {
          id
          behaviour
          status
          colour
        }
        actions {
          id
          description
          status
          behaviour
          behaviourId
        }
        status
        userId
        editors
        viewers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const habitsByUserByStatus = /* GraphQL */ `
  query HabitsByUserByStatus(
    $userId: String
    $statusCreatedAt: ModelHabitsByUserByStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHabitsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    habitsByUserByStatus(
      userId: $userId
      statusCreatedAt: $statusCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        identityGoal
        weeklyReflectionDay
        log {
          content
          type
          createdAt
        }
        behaviours {
          id
          behaviour
          status
          colour
        }
        actions {
          id
          description
          status
          behaviour
          behaviourId
          notifyTimestamp
        }
        status
        userId
        editors
        viewers
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const mealPlanByUserByStatus = /* GraphQL */ `
  query MealPlanByUserByStatus(
    $userId: String
    $statusStartingAt: ModelMealPlanByUserByStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMealPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mealPlanByUserByStatus(
      userId: $userId
      statusStartingAt: $statusStartingAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        kilojoules
        mealGroups {
          type
          count
        }
        meals {
          items {
            id
            mealPlanId
            mealId
            meal {
              id
              author
              source {
                name
                avatar
                url
              }
              title
              image
              description
              mealTypes
              dietary
              allergens
              cuisines
              tags
              cookingMethods
              cookingSkill
              ingredients {
                items {
                  id
                  mealId
                  foodId
                  food {
                    id
                    item
                    measurementUnits
                    unit
                    aisle
                    allergens
                    density
                    createdAt
                    updatedAt
                  }
                  item
                  qty
                  unit
                  isOptional
                  createdAt
                  updatedAt
                }
                nextToken
              }
              serves
              instructions {
                step
                direction
              }
              times {
                total
                prep
                cook
              }
              nutrition {
                kilojoules
                carbohydrates
                fat
                protein
                fibre
                iron
                calcium
              }
              notes
              status
              showInRecipe
              updatedAt
              createdAt
            }
            type
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        startingAt
        status
        userId
        mealPlanSetId
        editors
        viewers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMealPlan = /* GraphQL */ `
  query GetMealPlan($id: ID!) {
    getMealPlan(id: $id) {
      id
      type
      kilojoules
      mealGroups {
        type
        count
      }
      meals {
        items {
          id
          mealPlanId
          mealId
          meal {
            id
            author
            source {
              name
              avatar
              url
            }
            title
            image
            description
            mealTypes
            dietary
            allergens
            cuisines
            tags
            cookingMethods
            cookingSkill
            ingredients {
              items {
                id
                mealId
                foodId
                food {
                  id
                  item
                  measurementUnits
                  unit
                  aisle
                  allergens
                  density
                  createdAt
                  updatedAt
                }
                item
                qty
                unit
                isOptional
                createdAt
                updatedAt
              }
              nextToken
            }
            serves
            instructions {
              step
              direction
            }
            times {
              total
              prep
              cook
            }
            nutrition {
              kilojoules
              carbohydrates
              fat
              protein
              fibre
              iron
              calcium
            }
            notes
            status
            showInRecipe
            updatedAt
            createdAt
          }
          type
          note
          createdAt
          updatedAt
        }
        nextToken
      }
      startingAt
      status
      userId
      mealPlanSetId
      editors
      viewers
      createdAt
      updatedAt
    }
  }
`;
export const getAssessment = /* GraphQL */ `
  query GetAssessment($id: ID!) {
    getAssessment(id: $id) {
      id
      userId
      forms {
        typeformId
        title
        submittedAt
      }
      insightId
      insight {
        id
        teamId
        dueDate
        userCount
        createdAt
        updatedAt
      }
      status
      completedAt
      createdAt
      updatedAt
    }
  }
`;
export const getAssessmentUnauthenticated = /* GraphQL */ `
  query GetAssessment($id: ID!) {
    getAssessment(id: $id) {
      id
      userId
      forms {
        typeformId
        title
        submittedAt
      }
      insightId
      status
      completedAt
      createdAt
      updatedAt
    }
  }
`;
export const assessmentByUser = /* GraphQL */ `
  query AssessmentByUser(
    $userId: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    assessmentByUser(
      userId: $userId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        forms {
          typeformId
          title
          submittedAt
        }
        insightId
        insight {
          id
          title
          dueDate
          createdAt
          updatedAt
        }
        status
        completedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;