import React, { useState } from 'react';
import { Widget } from '@typeform/embed-react';
import { useParams, useHistory } from 'react-router-dom';
import { GetAssessmentQuery, User } from '../../API';
import DashboardLayout from '../../layouts/Dashboard';
import UnauthenticatedLayout from '../../layouts/Unathenticated';
import { useOfflineStorage } from '../../context/OfflineStorageProvider';
import { useUser } from '../../context/UserProvider';
import { API } from 'aws-amplify';
import { getAssessmentUnauthenticated } from '../../graphql/queries';
import { GraphQLResult, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import Loading from '../../components/Loading';

const FormWidget = (props: { typeformId: string; userId: string; assessmentId: string; user?: User; }) => {
  const { typeformId, userId, assessmentId, user } = props;
  const history = useHistory();
  const { setOffline } = useOfflineStorage();

  return (
    <Widget
      id={typeformId}
      className="h-100"
      hidden={{
        user_id: userId,
        full_name: user?.contactInfo?.firstName && user?.contactInfo?.lastName ? `${user.contactInfo.firstName.concat(' ', user.contactInfo.lastName)}` : '',
        email: user?.contactInfo?.email ? `${user.contactInfo.email}` : '',
        phone: user?.contactInfo?.phone ? `${user.contactInfo.phone}` : '',
        assessment_id: assessmentId,
        sex: (user?.profile?.sex || 'Female').toLowerCase()
      }}
      onSubmit={async () => {
        // Prevent the typeform showing a create typeform page to the user after submission.
        await setOffline<string | undefined>('completedFormId', typeformId).finally(() => {
          history.push(`/insights/assessment/${assessmentId}/forms`);
        });
      }}
    />
  );
};

const FormEmbed = () => {
  const { user } = useUser();
  const { typeformId, assessmentId } = useParams<{ typeformId: string; assessmentId: string; }>();
  const [userId, setUserId] = useState<string | null>();
  const history = useHistory();

  React.useEffect(() => {
    if (assessmentId && !userId) {
      const getData = async () => {
        const response = await API.graphql({
          query: getAssessmentUnauthenticated,
          variables: { id: assessmentId },
          authMode: GRAPHQL_AUTH_MODE.API_KEY
        }) as GraphQLResult<GetAssessmentQuery>;

        if (response.data?.getAssessment && !response.errors) {
          const form = response.data.getAssessment.forms.find((form) => form?.typeformId === typeformId);
          if (!form?.submittedAt) setUserId(response.data?.getAssessment.userId);
          else history.replace(`/insights/assessment/${assessmentId}/forms`);
        }
      };
      getData().then();
    }
  }, [assessmentId, userId, history, typeformId]);

  if (!userId) return <Loading />;

  if (user) {
    return (
      <DashboardLayout title="Insights" showBackButton hideSupport scrollYDisabled>
        {!!typeformId && <FormWidget typeformId={typeformId} userId={user?.id} user={user} assessmentId={assessmentId} />}
      </DashboardLayout>
    );
  } else {
    return (
      <UnauthenticatedLayout title="Insights">
        {!!typeformId && <FormWidget typeformId={typeformId} userId={userId} assessmentId={assessmentId} />}
      </UnauthenticatedLayout>
    );
  }
};

export default FormEmbed;
