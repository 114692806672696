import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonLoading } from '@ionic/react';
import animationData from '../animations/17100-food.json';
import loadingMessages from '../constants/loadingMessages.json';

const rowStyle = {
  height: '100vh'
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const Loading = (props: { useIonLoading?: boolean; message?: string; }) => {
  const { useIonLoading, message } = props;
  const [loadingMessage, setLoadingMessage] = useState(message);
  useEffect(() => {
    if (!loadingMessage) {
      const rand = new Date().getMinutes() * 2;
      setLoadingMessage(loadingMessages[rand]);
    }
  }, [loadingMessage]);

  if (useIonLoading) return (
    <IonLoading isOpen={!!loadingMessage} message={loadingMessage} />
  );

  return (
    <IonPage>
      <IonContent fullscreen={true}>
        <IonGrid>
          <IonRow className="ion-justify-content-center ion-align-items-center" style={rowStyle}>
            <IonCol size="12" className="ion-text-center">
              <div style={{ height: '100px' }}>
                {loadingMessage}
              </div>
              <Lottie options={defaultOptions} height={120} width={120} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>

  );
};

export default Loading;