import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import UserProvider from './context/UserProvider';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import { ExtraErrorData as ExtraErrorDataIntegration } from "@sentry/integrations";
import ReactGA from 'react-ga';
import { version } from '../package.json';
import './fontawesome';
import AppConfigProvider from './context/AppConfigProvider';
import AuthProvider from './context/AuthProvider';

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID || 'UA-000000-01');

const sentryParams = {
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV,
  release: "client@" + version,
  normalizeDepth: 6,
  integrations: [new ExtraErrorDataIntegration({ depth: 5 })]
};
Sentry.init(sentryParams);

// ReactGA UA-85219252-1

ReactDOM.render(
  <AppConfigProvider>
    <AuthProvider>
      <UserProvider>
        <App />
      </UserProvider>
    </AuthProvider>
  </AppConfigProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
