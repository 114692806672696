import React, { useState } from 'react';
import { IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonImg, IonPage, IonRow, IonText, IonItem, IonIcon, IonLabel } from '@ionic/react';
import { version } from "../../../package.json";
import compeatLogo from '../../assets/compeat/logo_transparent_bw.png';
import { Formik, Form, Field } from 'formik';
import classNames from "classnames";
import { mail } from 'ionicons/icons';
import { useAuth } from '../../context/AuthProvider';
import * as Yup from 'yup';

const buttonStyle = {
  background: 'white',
  color: 'var(--ion-color-dark)',
  borderRadius: '4px',
  textTransform: 'none',
  boxShadow: 'var(--box-shadow)',
  marginTop: '12px',
  height: '48px'
};

const buttonIconStyle = {
  height: '30px',
  width: '30px',
  marginTop: '6px',
  marginBottom: '6px',
  marginRight: '16px'
};

const EmailConfirmation = () => {
  const { sendPasswordReset } = useAuth();
  const [success, setSuccess] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email required').min(1, 'Email too short')
  });

  return (
    <IonPage className="authentication">
      <IonContent className="bg-sign-in">
        <IonGrid className="overlay">
          <IonRow className="d-flex justify-content-center align-items-center">
            <div className="app-navbar-notch">&nbsp;</div>
            {success ?
              <div className="position-absolute top-50 m-3">
                <div className="alert alert-success alert-dismissible fade show" role="alert">
                  <h1 className="alert-heading h4">Password Recovery</h1>
                  <p>We've sent an email with a reset link, click on the link when you get it.</p>
                  <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setSuccess(false)}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              :
              <IonCol
                size-xs="12"
                size-sm="8"
                size-lg="6"
                size-xl="3"
                className="d-flex flex-column justify-content-center align-items-stretch"
              >
                <IonCard color="primary" className="ion-text-center ion-no-border">
                  <IonImg src={compeatLogo} style={{ height: "102px" }} />
                  <IonCardContent>
                    <div>
                      <h1 className="h-3">Forgotten your password</h1>
                      <p className="my-3">Don't worry, it happens to the best of us</p>
                    </div>
                    <Formik
                      initialValues={{ email: '' } as {email : string}}
                      validationSchema={validationSchema}
                      onSubmit={async (values: {email : string}) => {
                        if (sendPasswordReset) {
                          await sendPasswordReset(values.email);
                        }
                        setSuccess(true);
                      }}
                    >
                      {(props) => (
                        <Form>
                          <>
                            <div className="px-2 pb-4">
                              <Field
                                name="email"
                                placeholder="Email Address"
                                required
                                style={buttonStyle}
                                type="email"
                                className={classNames('form-control', { 'is-invalid': props.errors.email && props.touched.email })}
                              />
                              {(props.touched.email) && props.errors.email &&
                                <div className="text-left invalid-feedback">{props.errors.email}</div>}
                              <IonItem
                                button
                                className="login-button"
                                style={buttonStyle}
                                onClick={() => props.handleSubmit()}
                              >
                                <IonIcon
                                  slot="start"
                                  style={buttonIconStyle}
                                  icon={mail}
                                  color="primary"
                                />
                                <IonLabel>
                                  Email me a recovery link
                                </IonLabel>
                              </IonItem>
                            </div>
                          </>
                        </Form>
                      )}
                    </Formik>
                  </IonCardContent>
                </IonCard>
                <IonText className="ion-text-right text-muted">
                  <small>version: {version}</small>
                </IonText>
              </IonCol>
            }
            <div className="app-navbar-notch">&nbsp;</div>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default EmailConfirmation;
