import { IonAlert, IonItem, IonIcon, IonText, IonThumbnail, IonItemDivider } from "@ionic/react";
import { mail, logoApple, checkmarkCircle } from "ionicons/icons";
import React, { ReactNode, useEffect, useState } from "react";
import './LoginForm.scss';
import { useHistory } from "react-router-dom";
import { useUser } from "../context/UserProvider";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import { Capacitor } from "@capacitor/core";
import { useAuth } from "../context/AuthProvider";
import googleLogo from '../assets/icons/g-logo.png';

const buttonStyle = {
  background: 'white',
  color: 'var(--ion-color-dark)',
  borderRadius: '4px',
  textTransform: 'none',
  boxShadow: 'var(--box-shadow)',
  marginTop: '12px',
  height: '48px'
};

const buttonIconStyle = {
  height: '30px',
  width: '30px',
  marginTop: '6px',
  marginBottom: '6px',
  marginRight: '16px'
};

const appleButtonIconStyle = {
  ...buttonIconStyle,
  ...{ color: 'black', paddingBottom: '2px' }
};

const LoginForm = (props: {
  children?: ReactNode,
  socialButtonActionLabel?: string;
}) => {
  const { socialLogin, passwordLogin, error } = useAuth();
  const { user } = useUser();
  const history = useHistory();
  const { children, socialButtonActionLabel } = props;
  const buttonActionLabel = socialButtonActionLabel || 'Continue';
  const showAppleConsent = !!user;
  let passwordErrors: string[] | null = null;  // Use for showing password criteria
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email required'),
    password: Yup.string().min(6, 'Password too short').required('Password required'),
  });

  useEffect(() => {
    if (user?.id) {
      setIsLoading(false);
    }
  }, [user]);

  const validatePassword = async (values: { email: string; password: string; }) => {
    try {
      // `{ abortEarly: false }` is optional, but shows all the failed password conditions.
      await validationSchema.validate(values, { abortEarly: false });
      passwordErrors = [];
    } catch (e: any) {
      passwordErrors = e.errors;
    }
  };

  return (
    <>
      {error ?
        <IonAlert
          header={'Oh Nutmeg!'}
          message={error}
          isOpen={true}
          buttons={['OK']}
        />
        : null}
      <div className="px-2">
        <IonItem
          lines="none"
          button
          style={buttonStyle}
          className="login-button"
          onClick={() => socialLogin("google.com")}
        >
          <IonThumbnail style={buttonIconStyle} slot="start">
            <img src={googleLogo} alt="Sign in with Google" />
          </IonThumbnail>
          <IonText slot="start">{buttonActionLabel} with Google</IonText>
        </IonItem>

        <IonItem
          lines="none"
          button
          style={buttonStyle}
          className={classNames("mb-2 login-button", { "d-none": Capacitor.platform === 'android' })}
          onClick={() => showAppleConsent ? history.push('/consent') : socialLogin("apple.com")}
        >
          <IonIcon
            slot="start"
            style={appleButtonIconStyle}
            icon={logoApple}
          />
          <IonText slot="start">{buttonActionLabel} with Apple</IonText>
        </IonItem>

        <IonItemDivider color="transparent" className="border-0 pl-0">
          <IonText className="ion-padding-vertical w-100 text-center"> - or - </IonText>
        </IonItemDivider>

        <Formik
          validateOnBlur={false}
          initialValues={{ email: user?.contactInfo?.email || user?.email || '', password: '' } as { email: string; password: string; }}
          validationSchema={validationSchema}
          onSubmit={async (values: { email: string; password: string; }) => {
            setIsLoading(true);
            passwordLogin(values.email, values.password);
          }}
        >
          {props => (
            <form onSubmit={props.handleSubmit} className="needs-validation auth-form">
              <Field
                name="email"
                placeholder="Email Address"
                required
                style={buttonStyle}
                type="email"
                className={classNames('form-control', { 'is-invalid': props.errors.email && props.touched.email })}
              />
              {(props.touched.email) && props.errors.email &&
                <div className="text-left invalid-feedback">{props.errors.email}</div>}

              <Field
                name="password"
                placeholder="Password"
                required
                style={buttonStyle}
                type="password"
                className={classNames('form-control', { 'is-invalid': props.errors.password && props.touched.password })}
                validate={validatePassword(props.values)}
              />
              {(props.touched.password) && props.errors.password &&
                <div className="text-left invalid-feedback">{props.errors.password}</div>}
              <p className={classNames('text-left pl-1 pt-1', { 'text-grey': (passwordErrors === null || (passwordErrors?.length && passwordErrors?.includes('Password too short'))) })}>
                <IonIcon icon={checkmarkCircle} className="align-text-bottom" /> 6 characters minimum
              </p>
              <IonItem
                lines="none"
                style={buttonStyle}
                className={classNames('login-button', { 'disabled': isLoading })}
                button
                onClick={() => props.handleSubmit()}
                disabled={isLoading}
              >
                <IonIcon
                  slot="start"
                  style={buttonIconStyle}
                  icon={mail}
                  color="primary"
                />
                <IonText slot="start">
                  {buttonActionLabel}
                </IonText>
              </IonItem>
            </form>
          )}
        </Formik>


        <IonItemDivider color="transparent" className="border-0 pl-0">
          <IonText className="ion-padding-vertical w-100 text-center">
            We won't post to any of your accounts without asking
          </IonText>
        </IonItemDivider>
      </div>
      {children}
    </>
  );
};

export default LoginForm;
