import API, { graphqlOperation, GraphQLResult } from '@aws-amplify/api';
import * as Sentry from '@sentry/react';

interface Dictionary<T> {
  [index: string]: T;
}

export default async function loadData<Q extends unknown, T extends unknown>(key: string, graphql: string, variables: Record<string, unknown> & { nextToken?: string | null, limit?: number; } = {}) {
  const callAPI = async <T extends unknown>(graphql: string, variables = {}, nextToken?: string | null) => {
    return await (API.graphql(graphqlOperation(graphql, { ...variables, nextToken: nextToken })) as Promise<GraphQLResult<T>>).catch((reason) => {
      Sentry.captureException(reason);
      return reason;
    });
  };

  type CallAPI = { data: Dictionary<{ items?: T[] | null, nextToken?: string | null; }>, errors?: Error[]; } | undefined;

  let results: T[] | null | undefined;
  let result = await callAPI<Q>(graphql, variables, variables?.nextToken) as CallAPI;
  let errors = result?.errors;
  if (errors || !result?.data || !Object.keys(result?.data).includes(key)) {
    Sentry.captureException({ data: result?.data, errors: errors });
    return { items: result?.data && Object.keys(result?.data).includes(key) ? result?.data[key]?.items : null, errors: errors, nextToken: null };
  }
  let nextToken = result?.data[key].nextToken;  // This will happen when the number of items returned due to page limit of AWS matches the specified limit. i.e. null when all retrieved in one call.
  if (result?.data[key].items?.length) {
    results = result.data[key].items;
    while (((result?.data[key].nextToken?.length && !variables.limit) || (nextToken?.length && result && results && variables?.limit && (results?.length || 0) < variables?.limit)) && !errors) {
      let callVariables = variables;
      if (variables?.limit) {
        callVariables = { ...variables, limit: variables.limit - (results?.length || 0) };
      }
      result = await callAPI<Q>(graphql, callVariables, result.data[key].nextToken) as CallAPI;
      errors = result?.errors;
      nextToken = result?.data[key].nextToken;
      if (result?.data[key].items?.length && results) {
        const list = result.data[key].items as T[];
        results = [...results, ...list];
      }
    }
  }
  if (errors) console.log(graphql, errors);
  return { items: results, errors: errors, nextToken: nextToken };
}
