import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../helpers/useQuery';
import { useAuth } from '../../context/AuthProvider';
import Loading from '../../components/Loading';

const VerifyEmail = () => {
  const { verifyEmail } = useAuth();
  const code = useQuery().get('oobCode') || '';
  const [message] = useState('Verifying Email');
  const [loaded, setLoaded] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const verify = async (code: string) => {
      await verifyEmail(code);
    };
    if (code) {
      verify(code).finally(() => setLoaded(true));
    }
  }, [code, verifyEmail]);

  useEffect(() => {
    if (loaded) {
      history.replace('/meals');
    }
  }, [loaded, history]);

  return <Loading message={message} />;
};

export default VerifyEmail;
