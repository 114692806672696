import React, { useState, useEffect } from 'react';
import { IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonImg, IonPage, IonRow, IonText, IonItem, IonIcon } from '@ionic/react';
import { version } from "../../../package.json";
import compeatLogo from '../../assets/compeat/logo_transparent_bw.png';
import { Formik, Form, Field } from 'formik';
import classNames from "classnames";
import { mail, checkmarkCircle } from 'ionicons/icons';
import { useAuth } from '../../context/AuthProvider';
import * as Yup from 'yup';
import { useQuery } from '../../helpers/useQuery';
import { Link } from 'react-router-dom';

const buttonStyle = {
  background: 'white',
  color: 'var(--ion-color-dark)',
  borderRadius: '4px',
  textTransform: 'none',
  boxShadow: 'var(--box-shadow)',
  marginTop: '12px',
  height: '48px'
};

const buttonIconStyle = {
  height: '30px',
  width: '30px',
  marginTop: '6px',
  marginBottom: '6px',
  marginRight: '16px'
};

const PasswordReset = () => {
  const { verifyPasswordCode, confirmPasswordReset } = useAuth();
  const [validCode, setValidCode] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const query = useQuery();
  const code = query.get('oobCode') || '';
  let passwordErrors: string[] | undefined = undefined;  // Use for showing password criteria, not state to prevent continual rendering
  const [highlightConditions, setHighlightConditions] = useState<boolean>(false);  // Prevent conditions dimming to grey on submit

  useEffect(() => {
    const verifyPassword = async () => {
      if (verifyPasswordCode) {
        const valid = await verifyPasswordCode(code);
        if (valid) {
          setValidCode(true);
        } else {
          triggerErrorMessage();
        }
      }
    };
    if (!validCode && !error && code) {
      verifyPassword();
    }
  }, [code, verifyPasswordCode, validCode, error]);

  const validationSchema = Yup.object().shape({
    password: Yup.string().min(6, 'Password too short').required('Password required'),
    passwordConfirm: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match')
  });

  const validatePassword = async (values: { password: string; passwordConfirm: string; }) => {
    try {
      // `{ abortEarly: false }` is optional, but shows all the failed password conditions.
      await validationSchema.validate(values, { abortEarly: false });
      passwordErrors = [];
    } catch (e: any) {
      passwordErrors = e.errors;
      // e.g. [
      //   'password must be at least X characters',
      //   'password must contain at least X upper-cased letter',
      // ]
    }
  };

  const triggerErrorMessage = () => {
    setError('Unable to reset password. Please try the forgot your password button again. If issue persists please contact support at support@compeatnutrtion.com');
  };

  return (
    <IonPage className="authentication">
      <IonContent className="bg-sign-in">
        <IonGrid className="overlay">
          <IonRow className="d-flex justify-content-center align-items-center">
            <div className="app-navbar-notch">&nbsp;</div>
            {!!error ?
              <div className="position-absolute top-50 m-3">
                <div className="alert alert-danger" role="alert">
                  <h1 className="alert-heading h4">Error</h1>
                  <p className="m-0">{error}</p>
                  <hr />
                  <Link to="/signin" className="alert-link m-0">Take me back to sign-in page</Link>
                </div>
              </div>
              :
              <IonCol
                size-xs="12"
                size-sm="8"
                size-lg="6"
                size-xl="3"
                className="d-flex flex-column justify-content-center align-items-stretch"
              >
                <IonCard color="primary" className="ion-text-center ion-no-border">
                  <IonImg src={compeatLogo} style={{ height: "102px" }} />
                  <IonCardContent>
                    <div>
                      <h1 className="h-3">Create a new password</h1>
                      <p className="my-3">You should try a password manager, we love LastPass and 1Password</p>
                    </div>

                    <Formik
                      initialValues={{ password: '', passwordConfirm: '' } as { password: string; passwordConfirm: string; }}
                      validationSchema={validationSchema}
                      onSubmit={async (values: { password: string; passwordConfirm: string; }) => {
                        setHighlightConditions(true);
                        // on success the confirm password method will login and redirect
                        if (confirmPasswordReset) {
                          const confirm = await confirmPasswordReset(code, values.password);
                          if (!validCode || !confirm) {
                            triggerErrorMessage();
                          }
                        }
                      }}
                    >
                      {(props) => (
                        <Form>
                          <>
                            <div className="px-2 pb-4">
                              <Field
                                name="password"
                                placeholder="Password"
                                required
                                style={buttonStyle}
                                type="password"
                                className={classNames('form-control', { 'is-invalid': props.errors.password && props.touched.password })}
                                validate={validatePassword(props.values)}
                              />
                              {(props.touched.password) && props.errors.password &&
                                <div className="text-left invalid-feedback">{props.errors.password}</div>}
                              <Field
                                name="passwordConfirm"
                                placeholder="Confirm Password"
                                required
                                style={buttonStyle}
                                type="password"
                                className={classNames('form-control', { 'is-invalid': props.errors.passwordConfirm && props.touched.passwordConfirm })}
                                validate={validatePassword(props.values)}
                              />
                              {(props.touched.passwordConfirm) && props.errors.passwordConfirm &&
                                <div className="text-left invalid-feedback">{props.errors.passwordConfirm}</div>}
                              <p className={classNames('text-left pl-1 pt-1', { 'text-grey': (!highlightConditions && (passwordErrors === null || (passwordErrors?.length && passwordErrors?.includes('Password too short')))) })}>
                                <IonIcon icon={checkmarkCircle} className="align-text-bottom" /> 6 characters minimum
                              </p>
                              <p className={classNames('text-left pl-1 pt-1', { 'text-grey': (!highlightConditions && (passwordErrors === null || (passwordErrors?.length && passwordErrors?.includes('Passwords must match')))) })}>
                                <IonIcon icon={checkmarkCircle} className="align-text-bottom" /> Passwords must match
                              </p>
                              <IonItem
                                button
                                className={classNames('login-button')}
                                style={buttonStyle}
                                onClick={() => props.handleSubmit()}
                              >
                                <IonIcon
                                  slot="start"
                                  style={buttonIconStyle}
                                  icon={mail}
                                  color="primary"
                                />
                                <IonText slot="start">
                                  Submit
                                </IonText>
                              </IonItem>
                            </div>
                          </>
                        </Form>
                      )}
                    </Formik>
                  </IonCardContent>
                </IonCard>
                <IonText className="ion-text-right text-muted">
                  <small>version: {version}</small>
                </IonText>
              </IonCol>
            }
            <div className="app-navbar-notch">&nbsp;</div>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default PasswordReset;
