import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import * as Sentry from '@sentry/react';
import { groupBy, isEqual, isNull, mergeWith, sortBy } from 'lodash';
import update from 'react-addons-update';
import { ZenObservable } from 'zen-observable-ts';
import API, { graphqlOperation, GraphQLResult } from '@aws-amplify/api';
import { GetUserQuery, ContactInfo, User, OnUpdateUserSubscription, OnCreateMessageSubscription, Alert } from '../API';
import { getUser, listUsers, listConversations, alertByUserByAction } from '../graphql/queries';
import { onUpdateUser, onCreateMessage } from '../graphql/subscriptions';
import { useAuth } from './AuthProvider';
import { useUser } from './UserProvider';
import { useAlerts } from './AlertProvider';
import { useOfflineStorage } from './OfflineStorageProvider';
import { Conversation, ListConversationsQuery, ListUsersQuery, AlertByUserByActionQuery, UpdateAlertMutation } from '../declarations/awsTypeDeclarations';
import loadData from '../helpers/graphqlPagination';
import { updateAlert } from '../graphql/mutations';

interface ProfileConversation extends Conversation {
  badge?: number;
}

type Profile = (User & { conversations: ProfileConversation[] | undefined }) | undefined;

interface Dictionary<T> {
  [index: string]: T;
}

interface ProfileContextInterface {
  profile: Profile;
  setProfile: Dispatch<SetStateAction<Profile>>;
  availableProfiles: Dictionary<User[]> | undefined;
  setCurrentProfileId: Dispatch<SetStateAction<string | undefined>>;
  setRefreshProfiles: Dispatch<SetStateAction<boolean>>;
  setRefreshConversations: (refreshConversations: boolean) => void;
  setDelayedRefresh: Dispatch<SetStateAction<boolean>>;
  badge: number;
}

const ProfileContext = React.createContext<ProfileContextInterface | null>(null);

type Contact = {
  id: string;
  contactInfo: ContactInfo;
}

type UserData = {
  profileUser: User | undefined;
  currentProfileId: string | undefined;
  previousProfileId: string | undefined;
  refreshConversations: boolean;
  profiles: User[];
};

const ProfileProvider: React.FC = (props) => {
  const { authUser } = useAuth();
  const { user } = useUser();
  const { notifications } = useAlerts();
  const { get, set } = useOfflineStorage();
  const [currentProfileId, setCurrentProfileId] = useState<string | undefined>();
  const [profile, setProfile] = useState<Profile>();
  const [userData, setUserData] = useState<UserData>({ profileUser: undefined, currentProfileId: undefined, previousProfileId: undefined, refreshConversations: false, profiles: [] });
  const [availableProfiles, setAvailableProfiles] = useState<Dictionary<User[]> | undefined>();
  const [refreshProfiles, setRefreshProfiles] = useState<boolean>(true);
  const [delayedRefresh, setDelayedRefresh] = useState<boolean>(false);
  const [badge, setBadge] = useState<number>(0);
  const [clearAlerts, setClearAlerts] = useState<boolean>(false);
  const [subscriptions, setSubscriptions] = useState<{ onCreateSubscriptions?: ZenObservable.Subscription[], onCreateConversations?: ProfileConversation[] } | undefined>();  // Prevent duplicate subscriptions for the same API subscription

  useEffect(() => {
    if (profile && profile?.conversations && clearAlerts) {
      loadData<AlertByUserByActionQuery, Alert>('alertByUserByAction', alertByUserByAction, { alertUserId: profile.id, actionCreatedAt: { beginsWith: { action: 'notification', createdAt: '20' } }, sortDirection: 'DESC', limit: 100 }).then((results) => {
        profile.conversations?.map((pc) => {
          const notifications = results.items?.filter((notification) => !notification.isRead && notification.content && !!notification.content.match(new RegExp(`link":"/specialist/${pc.id}`, "ig"))) || [];
          // Clear the unread notifications
          notifications.map((n) => {
            return (API.graphql(graphqlOperation(updateAlert, { input: { ...n, isRead: true } })) as Promise<GraphQLResult<UpdateAlertMutation>>).then();
          });
          setClearAlerts(false);
        });
      });
    }
  }, [clearAlerts, profile]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (delayedRefresh) {
      setDelayedRefresh(false);
      // Give the API time to set conversations inactive
      setTimeout(() => {
        // Trigger profile provider to refresh conversations
        setRefreshConversations(true);
      }, 10000);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [delayedRefresh]);

  useEffect(() => {
    if (profile) {
      setBadge(profile?.conversations?.reduce((acc, obj) => (acc + (obj.badge || 0)), 0) || 0);
    }
  }, [profile]);

  useEffect(() => {
    if (authUser) {
      get('currentProfileId').then(async (_currentProfileId) => {
        if (_currentProfileId && (typeof _currentProfileId === "string" || typeof _currentProfileId === "undefined")) {
          // if the user has a current profile Id set, then use that
          setCurrentProfileId(_currentProfileId);
        } else {
          // otherwise, grab the profile Id from the JWT
          const idToken = await authUser.getIdTokenResult(true);
          const compeatUserId = idToken.claims && idToken.claims.compeat_user_id ? idToken.claims.compeat_user_id : authUser.uid;
          setCurrentProfileId(compeatUserId);
        }
      });
    } else {
      // once the user logs out, remove the current profile Id
      setCurrentProfileId(undefined);
    }
  }, [authUser, get]);

  useEffect(() => {
    const fetchUsers = async () => {
      setRefreshProfiles(false);
      const callListUsers = async (nextToken?: string) => {
        return await (API.graphql(graphqlOperation(listUsers, { nextToken: nextToken, limit: 1000 })) as Promise<GraphQLResult<ListUsersQuery>>).catch((reason) => {
          Sentry.captureException(reason);
          return undefined;
        });
      };
      let result = await callListUsers();
      if (authUser && result?.data?.listUsers?.items) {
        let profiles: User[] = result.data.listUsers.items;
        while (result?.data?.listUsers?.nextToken?.length) {
          result = await callListUsers(result.data.listUsers.nextToken);
          if (result?.data?.listUsers?.items?.length) profiles = [...profiles, ...result.data.listUsers.items];
        }
        setUserData((userData) => ({ ...userData, profiles: profiles }));
        if (profiles.length === 0) {
          // NO PROFILE??!?!?! not sure how this could happen, but hey
          // grab the profile Id from the JWT
          const idToken = await authUser.getIdTokenResult(true);
          const compeatUserId = idToken.claims && idToken.claims.compeat_user_id ? idToken.claims.compeat_user_id : authUser.uid;
          setCurrentProfileId(compeatUserId);
        } else if (profiles.length === 1) {
          // only 1 profile, set it, this will override the previous current profile Id
          // so that if a user is removed as guardian they don't continue to have access
          const currentProfile = profiles[0];
          setCurrentProfileId(currentProfile.id);
        } else {
          const idToken = await authUser.getIdTokenResult(true);
          const compeatUserId = idToken.claims && idToken.claims.compeat_user_id ? idToken.claims.compeat_user_id : authUser.uid;
          const activeProfiles = profiles.slice().filter((profile: User) => !!profile.contactInfo?.email);
          setAvailableProfiles(groupBy(activeProfiles, (profile: User) => {
            if (profile.id === compeatUserId) return 'self';
            return 'others';
          }));
        }
      }
    };
    if (authUser && refreshProfiles) {
      fetchUsers();
    }
  }, [authUser, refreshProfiles]);

  useEffect(() => {
    setProfile(undefined);
    if (authUser && currentProfileId) {
      (API.graphql(graphqlOperation(getUser, { id: currentProfileId })) as Promise<{ data: GetUserQuery; }>).then(async (result) => {
        if (result.data.getUser) {
          await set('currentProfileId', currentProfileId);
          setUserData((userData) => ({ ...userData, profileUser: result.data.getUser as User, currentProfileId: currentProfileId }));
        } else {
          const idToken = await authUser.getIdTokenResult(true);
          const compeatUserId = idToken.claims && idToken.claims.compeat_user_id ? idToken.claims.compeat_user_id : authUser.uid;
          setCurrentProfileId(compeatUserId);
          setUserData((userData) => ({ ...userData, profileUser: undefined, currentProfileId: compeatUserId }));
        }
      }).catch((reason) => {
        Sentry.captureException(reason);
      });
    }
  }, [currentProfileId, authUser, set]);

  useEffect(() => {
    const processName = (contactInfo: ContactInfo | undefined) => {
      if (!contactInfo) return '';
      return `${contactInfo?.firstName?.trim() || ''} ${contactInfo?.lastName?.trim() || ''}`;
    };

    const fetchConversations = async () => {
      setUserData((userData) => ({ ...userData, previousProfileId: userData.currentProfileId, refreshConversations: false }));
      // Add conversations to active profile
      const callListConversations = async (nextToken?: string) => {
        return await (API.graphql(graphqlOperation(listConversations, { nextToken: nextToken, limit: 1000 })) as Promise<GraphQLResult<ListConversationsQuery>>).catch((reason) => {
          Sentry.captureException(reason);
          return undefined;
        });
      };

      let result = await callListConversations();
      let profileConversations: Conversation[] | null | undefined = result?.data?.listConversations?.items;
      if (result?.data?.listConversations?.items?.length) {
        const filterConversations = (conversationItems: Conversation[]): Conversation[] => {
          return conversationItems.filter((conversation: Conversation) => user && conversation.clientId === userData.currentProfileId && conversation.members?.includes(user.id || ''));
        };
        let permittedConversations: Conversation[] = filterConversations(result.data.listConversations.items.slice());
        while (result?.data?.listConversations?.nextToken?.length) {
          result = await callListConversations(result.data.listConversations.nextToken);
          if (result?.data?.listConversations?.items?.length) permittedConversations = [...permittedConversations, ...filterConversations(result.data.listConversations.items.slice())];
        }
        profileConversations = permittedConversations;
        if (!!permittedConversations.length) {
          profileConversations = permittedConversations.map((conversation: Conversation) => {
            const contacts = !availableProfiles || !conversation.members?.length ? [] : availableProfiles?.others?.filter((o: User) => user && o.id && conversation.members?.includes(o.id) && o.contactInfo?.userId && o.contactInfo.userId !== user.id) as Contact[] | null | undefined;
            let title: string | null | undefined = conversation.title;
            if (conversation.members?.length && conversation.members?.length > 2) {
              if (!!contacts?.length) {
                const names = !contacts?.length ? null : [conversation.specialist?.displayName, ...contacts?.map((contact: Contact) => (processName(contact.contactInfo))).filter((name: string) => !!name?.trim()?.length)];
                const numberNamesShown = 2;
                title = !names?.length ? '' : names.slice(0, numberNamesShown).join(', ') + (names.length > numberNamesShown ? `, +${names.length - numberNamesShown}` : '');
              } else if ((!title || !title.length)) {
                title = "Group Chat";
              }
            }
            return { ...conversation, contacts: contacts, title: title, badge: notifications?.filter((notification) => !notification.isRead && notification.content && !!notification.content.match(new RegExp(`link":"/specialist/${conversation.id}`, "ig")))?.length || 0 };
            // return { ...conversation, contacts: contacts, title: title };
          });
          profileConversations = sortBy(profileConversations, 'status');
        }
      }
      setProfile({ ...userData.profileUser as User, conversations: profileConversations ? profileConversations : undefined });
    };

    if (userData?.profileUser?.id && userData.currentProfileId && ((!userData?.previousProfileId && userData.profiles.length === 1) || (userData.previousProfileId !== userData.currentProfileId && !!availableProfiles) || userData.refreshConversations)) {
      fetchConversations();
    }
  }, [userData, availableProfiles, user, notifications]);

  // This is to update the profile for the current user and any other users connected to this user
  useEffect(() => {
    if (profile?.id) {
      const subscription = (API.graphql(graphqlOperation(onUpdateUser, { id: profile.id })) as Observable<{ value: GraphQLResult<OnUpdateUserSubscription> }>).subscribe({
        next: ({ value }: { value: GraphQLResult<OnUpdateUserSubscription> }) => {
          const updateUser = value.data?.onUpdateUser;
          let shouldRefresh = false;
          // remove the connection as they aren't returned
          // and the attributes that aren't changed by the system
          // so that we don't re-render for no reason
          const excludedFields = [
            'devices',
            'messages',
            'specialists',
            'contactInfo',
            'clientProfile',
            'updatedAt',
            'lastActiveAt',
            'stripeCustomerId',
            'paymentMethod'
          ];
          const profileValues = profile as Record<string, unknown>;
          const updateUserValues = updateUser as Record<string, unknown>;
          for (const key in updateUser) {
            if (!excludedFields.includes(key) && !isEqual(updateUserValues[`${key}`], profileValues[`${key}`])) {
              shouldRefresh = true;
            }
          }

          if (shouldRefresh) {
            const _user = mergeWith({}, profile, updateUser, (o, s) => {
              // This logic is here to keep any existing connections on the user.
              // That is because the publishUser mutation in the API does not have the connections.
              // It is assumed that connections other than contactInfo have items.
              // FirstName is looked for as it is in contactInfo
              if (isNull(s) && (o?.hasOwnProperty('items') || (o && Object.keys(o).includes('firstName')))) return o;
              return s;
            });
            subscription.unsubscribe();  // Close subscription as another will be opened when profile set.
            setProfile((profile) => ({ ..._user as User, conversations: profile?.conversations }));
          }
        },
        error: (error) => {
          Sentry.captureException(error);
        }
      });
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [profile]);


  useEffect(() => {
    if (profile?.conversations && (!isEqual(profile.conversations, subscriptions?.onCreateConversations) || !subscriptions?.onCreateSubscriptions || subscriptions.onCreateSubscriptions.some((s) => s.closed)) && setProfile) {
      let timer: NodeJS.Timeout;
      subscriptions?.onCreateSubscriptions?.forEach((s) => s.unsubscribe());  // Unsubscribe when change profile
      setSubscriptions((subscriptions) => {
        return {
          ...subscriptions,
          onCreateSubscriptions: profile.conversations?.map((pc) => (API.graphql(graphqlOperation(onCreateMessage, { messageConversationId: pc.id })) as Observable<{ value: GraphQLResult<OnCreateMessageSubscription> }>).subscribe({
            next: ({ value }: { value: GraphQLResult<OnCreateMessageSubscription> }) => {
              setProfile((profile) => {
                // To update chat list view
                const profileIndex = profile?.conversations?.findIndex((c) => c.id === pc.id);
                const conversation = profile?.conversations && profileIndex !== undefined && profileIndex > -1 ? profile.conversations[profileIndex] : undefined;
                let badge = 0;
                if (!window.location.pathname.includes(`/specialist/${pc.id}`)) {
                  badge = (conversation?.badge || 0) + 1;
                } else {
                  // Used to clear an alert in alert table when on the page the alert is related to.
                  // Here because this is the event that creates the alert. The alert is created in the API without a mutation so an alert subscription can't be used instead
                  // As the alert is created in the API after the onCreateMessage lambda we need to give the alert table time to receive the entry before we try to clear it.
                  timer = setTimeout(() => {
                    setClearAlerts(true);
                  }, 1000);
                }
                return value.data?.onCreateMessage && profile && conversation ? update(profile, { $merge: { conversations: profile.conversations ? update(profile.conversations, { $splice: [[profileIndex, 1, update(conversation, { $merge: { messages: { items: conversation.messages?.items ? update(conversation.messages.items, { $unshift: [value.data.onCreateMessage] }) : [] }, badge: badge } })]] }) : [] } }) : profile;
              });

            }, error: (error) => {
              Sentry.captureException(error);
            }
          })),
          onCreateConversations: profile.conversations
        };
      });
      return () => {
        if (timer) clearTimeout(timer);
        subscriptions?.onCreateSubscriptions?.forEach((s) => s.unsubscribe());
      };
    }
  }, [profile, subscriptions, setProfile]);

  const setRefreshConversations = (refreshConversations: boolean) => {
    setUserData((userData) => ({ ...userData, refreshConversations: refreshConversations }));
  };

  return (
    <ProfileContext.Provider value={{ profile, setProfile, availableProfiles, setCurrentProfileId, setRefreshProfiles, setRefreshConversations, setDelayedRefresh, badge }} {...props} />
  );
};

const useProfile = () => {
  const context = React.useContext(ProfileContext) as ProfileContextInterface;
  if (context === undefined) {
    throw new Error(`useProfile must be used within a ProfileProvider`);
  }
  return context;
};

export { useProfile };
export default ProfileProvider;
