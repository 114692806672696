import mixpanel from 'mixpanel-browser';
import React from 'react';
import { useEffect } from 'react';
import { version } from '../../package.json';

interface AppConfigContextInterface {
  version: string;
}

const AppConfigContext = React.createContext<AppConfigContextInterface | null>(null);

const AppConfigProvider: React.FC = (props) => {
  useEffect(() => {
    fetch('/config.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      if (version < data.minVersion && data.forceUpdate) {
        console.log(`%cApp Update Required:%cv${data.minVersion}`, 'font-size: 12px; color: #fff; background-color: #FF0000; padding: 2px 4px;', 'color: #FF0000; background-color: transparent; padding: 2px 4px;');
        window.location.reload();
      }
    });
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_MIXPANEL_TOKEN) mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
  }, []);

  return (
    <AppConfigContext.Provider value={{ version }} {...props} />
  );
};

const useConfig = () => {
  const context = React.useContext(AppConfigContext) as AppConfigContextInterface;
  if (context === undefined) {
    throw new Error(`useConfig must be used within a AppConfigProvider`);
  }
  return context;
};

export { useConfig };
export default AppConfigProvider;
