import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import * as Sentry from '@sentry/react';
import { Capacitor, Plugins } from '@capacitor/core';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { getAuth, GoogleAuthProvider, OAuthProvider, signInWithCredential } from 'firebase/auth';
import { Cache, API } from 'aws-amplify';
import mixpanel from 'mixpanel-browser';

type User = firebase.User;

interface AuthContextInterface {
  authUser?: User | undefined;
  loading: boolean;
  anonymousLogin: () => void;
  socialLogin: (provider: string) => Promise<void>;
  logout: () => void;
  passwordLogin: (email: string, password: string) => void;
  error?: string | undefined;
  sendPasswordReset?: (email: string) => Promise<boolean | undefined>;
  verifyPasswordCode?: (code: string) => Promise<string | undefined>;
  confirmPasswordReset?: (code: string, newPassword: string) => Promise<boolean | undefined>;
  verifyEmail: (code: string) => Promise<void | undefined>;
}

const AuthContext = React.createContext<AuthContextInterface | null>(null);

const AuthProvider: React.FC = (props) => {
  const [authUser, setAuthUser] = useState<User | undefined>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>();
  const [passwordResetEmail, setPasswordResetEmail] = useState<string | undefined>();

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (_authUser: firebase.User | null) => {
      setPasswordResetEmail(undefined);
      if (_authUser) {
        if (_authUser.isAnonymous) {
          firebase.auth().signOut();
        } else {
          await _authUser.getIdToken(true).then((idToken: string) => {
            Cache.setItem('federatedInfo', { token: idToken });
          });
        }
      } else {
        Cache.removeItem('federatedInfo');
      }
      setAuthUser(_authUser || undefined);
      setLoading(false);
    });
  }, []);

  const anonymousLogin = () => {
    firebase.auth().signInAnonymously().catch((error) => {
      Sentry.captureException(error);
    });
  };

  const passwordLogin = (email: string, password: string) => {
    if (authUser?.isAnonymous) {
      passwordLink(email, password);
    } else {
      firebase.auth().signInWithEmailAndPassword(email, password).then(() => {
        mixpanel.track('Signed In');
      }).catch((error) => {
        if (error.code === 'auth/user-not-found') {
          firebase.auth().createUserWithEmailAndPassword(email, password).then(() => {
            mixpanel.track('User Created');
          }).catch((error) => {
            Sentry.captureException(error);
            setError(error.message);
          });
        } else {
          Sentry.captureException(error);
          setError(error.message);
        }
      });
    }
  };

  const passwordLink = (email: string, password: string) => {
    const credential = firebase.auth.EmailAuthProvider.credential(email, password);
    firebase.auth().currentUser?.linkWithCredential(credential).then((userCred) => {
      const user = userCred.user;
      console.log("Anonymous account successfully upgraded", user);
    }).catch((error) => {
      Sentry.captureException(error);
      console.log("Error upgrading anonymous account", error);
    });
  };

  // https://www.npmjs.com/package/@capacitor-firebase/authentication
  // https://github.com/capawesome-team/capacitor-firebase/blob/HEAD/packages/authentication/docs/firebase-js-sdk.md
  const signInWithGoogle = async () => {
    // 1. Create credentials on the native layer
    const result = await FirebaseAuthentication.signInWithGoogle();
    // 2. Sign in on the web layer using the id token
    const credential = GoogleAuthProvider.credential(result.credential?.idToken);
    await signInWithCredential(getAuth(), credential).catch((error) => {
      Sentry.captureException(error);
      setError(error.message);
    });
  };

  const linkWithGoogle = async () => {
    // 1. Create credentials on the native layer
    await FirebaseAuthentication.signInWithGoogle();
    // 2. Link credentials
    await FirebaseAuthentication.linkWithGoogle().catch((error) => {
      Sentry.captureException(error);
      setError(error.message);
    });
  };

  const signInWithApple = async () => {
    // 1. Create credentials on the native layer
    const result = await FirebaseAuthentication.signInWithApple({
      skipNativeAuth: true,
    });
    // 2. Sign in on the web layer using the id token and nonce
    const provider = new OAuthProvider('apple.com');
    const credential = provider.credential({
      idToken: result.credential?.idToken,
      rawNonce: result.credential?.nonce,
    });

    await signInWithCredential(getAuth(), credential).catch((error) => {
      // An error occurred. If error.code == 'auth/missing-or-invalid-nonce',
      // make sure you're sending the SHA256-hashed nonce as a hex string
      // with your request to Apple.
      Sentry.captureException(error);
      setError(error.message);
    });
  };

  const linkWithApple = async () => {
    // 1. Create credentials on the native layer
    await FirebaseAuthentication.signInWithApple({ skipNativeAuth: true });
    // 2. Link credentials
    await FirebaseAuthentication.linkWithApple().catch((error) => {
      Sentry.captureException(error);
      setError(error.message);
    });
  };

  const nativeSocialLogin = async (provider: string) => {
    switch (provider) {
      case "apple.com":
        await signInWithApple();
        break;
      case "google.com":
        await signInWithGoogle();
        break;
      case "google.com":
        //TODO: Resolve
        // FirebaseAuthentication.signInWithGoogle();
        break;
      default:
        Sentry.captureException(new Error(`No login method defined for provider: ${provider}`));
        break;
    }
  };

  const nativeSocialLink = async (provider: string) => {
    switch (provider) {
      case "apple.com":
        await linkWithApple();
        break;
      case "google.com":
        await linkWithGoogle();
        break;
      default:
        //TODO: Replace with package or firebase auth implementation
        const plugin = Plugins.CapacitorFirebaseAuth;
        let providerId;
        switch (provider) {
          case "google.com":
            providerId = firebase.auth.GoogleAuthProvider.PROVIDER_ID;
            break;
          case "facebook.com":
            providerId = firebase.auth.FacebookAuthProvider.PROVIDER_ID;
            break;
          default:
            const authProvider = new firebase.auth.OAuthProvider(provider);
            providerId = authProvider.providerId;
        }

        // native sign in
        try {
          plugin.signIn({ providerId }).then((result: { idToken: string | null | undefined; }) => {
            // create the credentials
            const credential = firebase.auth.GoogleAuthProvider.credential(result.idToken);
            // web sign in
            firebase.app().auth().currentUser?.linkWithCredential(credential).catch((error) => {
              console.log('linkWithCredential', error);
            });
          });
        } catch (e) {
          console.log('error', e);
        }
        break;
    }
  };

  const webSocialLogin = async (provider: string) => {
    let authProvider;
    switch (provider) {
      case "google.com":
        authProvider = new firebase.auth.GoogleAuthProvider();
        authProvider.addScope('profile');
        authProvider.addScope('email');
        break;
      default:
        authProvider = new firebase.auth.OAuthProvider(provider);
    }
    firebase.auth().signInWithPopup(authProvider).catch((error) => {
      Sentry.captureException(error);
    });
  };

  const webSocialLink = async (provider: string) => {
    let authProvider;
    switch (provider) {
      case "google.com":
        authProvider = new firebase.auth.GoogleAuthProvider();
        authProvider.addScope('profile');
        authProvider.addScope('email');
        break;
      default:
        authProvider = new firebase.auth.OAuthProvider(provider);
    }
    firebase.auth().currentUser?.linkWithPopup(authProvider).catch((error) => {
      setError(error.message);
    });
  };

  const socialLogin = async (provider: string): Promise<void> => {
    if (Capacitor.isNativePlatform()) {
      if (authUser?.isAnonymous) {
        await nativeSocialLink(provider);
      } else {
        await nativeSocialLogin(provider);
      }
    } else {
      if (authUser?.isAnonymous) {
        await webSocialLink(provider);
      } else {
        await webSocialLogin(provider);
      }
    }
  };

  const sendPasswordReset = async (email: string): Promise<boolean | undefined> => {
    if (email.length) {
      try {
        return await firebase.auth().sendPasswordResetEmail(email).then(() => {
          mixpanel.track('Password Reset Email Sent');
          return true;
        });
      } catch (e) {
        // Email does not exist or other error
        return undefined;
      }
    }
    return undefined;
  };

  const verifyPasswordCode = async (code: string): Promise<string | undefined> => {
    try {
      return await firebase.auth().verifyPasswordResetCode(code).then((email: string) => {
        setPasswordResetEmail(email);
        return email;
      });
    } catch (e) {
      // Invalid code
      return undefined;
    }
  };

  const confirmPasswordReset = async (code: string, newPassword: string): Promise<boolean | undefined> => {
    try {
      return await firebase.auth().confirmPasswordReset(code, newPassword).then(async () => {
        // Success
        if (passwordResetEmail) {
          // Log user in
          setPasswordResetEmail(undefined);
          const loggedIn = await logUserIn(passwordResetEmail, newPassword);
          if (loggedIn) {
            // Reset WordPress password
            const params = {
              body: {
                email: passwordResetEmail,
                newPassword: newPassword
              }
            };
            await API.post("REST_API", "/resetPassword", params);
          }
          return loggedIn;
        } else {
          // If email does not exist ignore login
          return true;
        }
      });
    } catch (e) {
      // Invalid code
      return undefined;
    }
  };

  const verifyEmail = async (code: string) => {
    try {
      return await firebase.auth().applyActionCode(code);
    } catch (e) {
      // Invalid code
      return undefined;
    }
  };

  const logUserIn = async (email: string, password: string) => {
    try {
      return await firebase.auth().signInWithEmailAndPassword(email, password).then(() => {
        return true;
      });
    } catch (e) {
      return undefined;
    }
  };

  const logout = () => {
    setPasswordResetEmail(undefined);
    setError(undefined);
    mixpanel.track('Log out');
    firebase.auth().signOut().catch((error) => {
      Sentry.captureException(error);
    });
  };

  return (
    <AuthContext.Provider value={{ authUser, loading, anonymousLogin, socialLogin, logout, passwordLogin, error, sendPasswordReset, verifyPasswordCode, confirmPasswordReset, verifyEmail }} {...props} />
  );
};

const useAuth = () => {
  const context = React.useContext(AuthContext) as AuthContextInterface;
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

export { useAuth };
export default AuthProvider;
