import React from 'react';
import { IonAvatar, IonBadge, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import { chevronDown, notifications as bell } from 'ionicons/icons';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import { useAlerts } from "../context/AlertProvider";
import './Notifications.scss';
import checkImageURL from '../helpers/checkImageURL';

const NotificationsButton = (props: { handleOpen: () => void; }) => {
  const { handleOpen } = props;
  const { notifications } = useAlerts();
  const unreadAlerts = notifications?.filter((alert) => !alert.isRead) || [];

  return (
    <IonButton
      className="alerts"
      onClick={handleOpen}
      disabled={notifications?.length === 0}
    >
      <IonIcon icon={bell} color={notifications?.length === 0 ? "medium" : "primary"} />
      {unreadAlerts.length ?
        <IonBadge className="alerts-badge" color="danger">
          {unreadAlerts.length}
        </IonBadge>
        : null}
    </IonButton>
  );
};

const NotificationsModal = (props: { isOpen: boolean; handleClose: () => void; }) => {
  const { isOpen, handleClose } = props;
  const { notifications } = useAlerts();
  const history = useHistory();

  const handleClick = (url: string) => {
    history.push(url);
    handleClose();
  };

  return (
    <IonModal
      isOpen={isOpen}
      swipeToClose
      onDidDismiss={handleClose}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>Notifications</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={handleClose}>
              <IonIcon icon={chevronDown} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {notifications?.map((alert, idx) => {
            if (alert && alert.content) {
              const content = JSON.parse(alert.content);
              return (
                <IonItem
                  key={idx}
                  button
                  color={alert.isRead ? "" : "light"}
                  onClick={() => {
                    handleClick(content.link);
                  }}
                >
                  <IonAvatar slot="start">
                    <img src={content.image || 'https://miro.medium.com/max/1424/1*c3cQvYJrVezv_Az0CoDcbA.jpeg'} alt="" />
                  </IonAvatar>
                  <IonLabel className="ion-text-wrap">
                    <h3>{content.message ? content.message.includes('firebasestorage') ? checkImageURL(content.message) ? 'New Image to view' : 'New File to view' : content.message : content.notification}</h3>
                    <p>{alert.createdAt ? (<Moment fromNow>{alert.createdAt}</Moment>) : 'never'}</p>
                  </IonLabel>
                </IonItem>
              );
            }
            return null;
          })}
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export { NotificationsButton, NotificationsModal };
