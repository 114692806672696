import React from 'react';
import PublicLayout from '../../layouts/Public';

const PrivacyPolicyView = () => {
  return (
    <PublicLayout>
      <div className="p-4">
        <h1>Privacy Policy</h1>
        <p>Compeat Nutrition Pty Ltd (ACN: 608 631 983) of 59 Parry St, Newcastle West NSW 2300 <b>(Compeat, we, us, our)</b> is committed to protecting your privacy. This Privacy Policy tells you about our collection of personal information and how we store, use and disclose that information. This site is owned by Compeat and may be accessed in Australia and overseas.</p>
        <p>The collection, use and disclosure of your personal information is governed by the Privacy Act 1988 (Cth) if you are in Australia or the General Data Protection Regulation <b>(GDPR)</b> if you are in a country that is a member of the European Union.</p>
        <p>This Privacy Policy applies whenever Compeat receives your personal information, as a data controller, for the purposes set out in this Privacy Policy. Those purposes include collecting and processing data so that you can engage with this site or as mentioned below. For data protection purposes Compeat is the controller and, unless otherwise noted, is also the processor of data</p>

        <h3>Consent</h3>
        <p>By visiting and/or using this site or when you obtain services from Compeat, you give your consent to our collection, use and disclosure of your personal information in accordance with this Privacy Policy. </p>
        <p>You can withdraw your consent at any time by contacting Compeat by email at <a href="mailto:privacy@compeatnutrition.com" target="_blank" rel="noopener noreferrer">privacy@compeatnutrition.com</a>, however, certain products and services may not be available to you.</p>

        <h3>What information we collect</h3>
        <p>This Privacy Policy covers personal information that you give to us or that we collect when you visit our website or obtain our services. Personal information is information that can be used to identify you. Personal information might include your name; street, mailing address and email address; telephone number; date of birth; gender; financial information such as your credit card number and billing address; profession, occupation or job title.</p>
        <p>We will need certain health information from you so that we can prepare a program for you. We will not ask for other types of sensitive information such as details of your racial or ethnic origin, political affiliations, religious beliefs, sexual preferences or criminal convictions unless we need it to provide you with professional advice. By giving us any of this information, you agree that you have opted in to providing that information to us.</p>
        <p>We try and collect personal information directly from the relevant person. However, if this is not practicable, we may collect personal information from third parties or publicly available information. If you give us personal information about another person, you must ensure you have the right to disclose that personal information to us.</p>

        <h3>Personal information automatically collected </h3>
        <p>When you visit this site, other kinds of information may be collected automatically as part of this site’s operation. This includes the website that referred you to us, your IP address, browser type and language, and access times. We may also collect navigational information, including information about the pages you view, the links you click, and other actions taken in connection with the site. We may combine your visit and navigational information with personal information that you provide.</p>
        <p>We use "cookies" to enable you to sign in to our services and to help personalise your online experience. A cookie is a small text file that is placed on your hard drive. Cookies contain information, including personal information that can later be read by a web server in the domain that issued the cookie to you. The information that cookies collect includes the date and time of your visit, your registration information, and your navigational and purchase history. Cookies offer you many conveniences. They allow us to identify registered users when they return to the site so that they can retrieve previous image search results and view their previous invoices. Cookies also save you time by eliminating the need to repeatedly enter the same information.</p>
        <p>In some cases, our third-party service providers may use cookies on our sites. We have no access to or control over these cookies. This Privacy Policy covers the use of cookies by Compeat only and does not cover the use of cookies by third parties.</p>
        <p>You have the ability to accept or refuse cookies. Most browsers automatically accept cookies, but you can usually modify your browser setting to refuse cookies. If you choose to refuse cookies, you may not be able to sign in or use other interactive features of our sites and services that depend on cookies.</p>
        <p>In addition to cookies, we may use other technologies, including single-pixel gifs (also known as web beacons) on our websites and in promotional e-mail messages or newsletters. These tiny electronic images assist us in determining how many users have visited certain pages or opened messages or newsletters. We do not use these images to collect personal information.</p>

        <h3>Primary Account Information Upload</h3>
        <p>Our app uploads your Primary account information to our secure server located at Amazon AWS. This information may include, but is not limited to, your email address, display name, and profile picture. We collect this information to provide you with a seamless experience within the app and to personalize the app's features and content according to your preferences.</p>
        <p>We take the security of your Primary account information seriously and implement appropriate technical and organizational measures to protect it during transmission and storage. Your data is encrypted during transit and stored securely on our servers.</p>
        <p>Our app is compliant with Google Play Console's privacy policy requirements. We have posted our privacy policy in the Play Console, which includes all necessary information about the collection, use, and disclosure of your personal information, as well as your rights regarding your data.</p>

        <h3>Use of Your Personal Information</h3>
        <p>We use the personal information you have given us for purposes which are consistent with the reason you provided the personal information, or for a directly related purpose. Such purposes for which we may use your personal information include:</p>
        <ul>
          <li>improving our service to you;</li>
          <li>doing business with you so that you can purchase our goods and services;</li>
          <li>effectively communicating with you;</li>
          <li>operating and improving our website;</li>
          <li>providing customer service;</li>
          <li>performing research and analysis aimed at improving our products and services; and</li>
          <li>displaying content that is customised to your interests and preferences.</li>
        </ul>
        <p>We may also use or disclose your personal information where you have consented to the use or disclosure, either expressly or by implication.</p>

        <h3>Sharing of Your Personal Information</h3>
        <p>We do not sell, rent or trade your personal information to or with any third parties.</p>
        <p>We may share your personal information with external service providers who perform services on our behalf. For example, we may hire other companies to handle the processing of payments, to provide data storage, to host websites, to fulfil orders and shipments, to assist in direct marketing, to conduct audits, etc. Those companies will be permitted to obtain only the personal information they need to provide the service. They are required to maintain the confidentiality of the information and are prohibited from using it for any other purpose. We will take all reasonable steps to make sure they comply with the same privacy principles that govern our collection of your personal information.</p>
        <p>Some of these service providers may be overseas. While we do our best to make sure that overseas service providers to whom we give your personal information will comply with the same privacy principles as us we cannot guarantee it.By giving us your personal information, you consent to the disclosure of personal information to overseas service providers who may not comply</p>
        <p>We may disclose your personal information if required to do so by law, or in the good-faith belief that such action is reasonably necessary to comply with legal process, respond to claims, or protect the rights, property or safety of our company, employees, customers, or the public.</p>

        <h3>Third-Party Data Sharing</h3>
        <p>We may share your personal information with external service providers who perform services on our behalf, such as payment processing, data storage, and analytics. We only share your personal data with service providers who have implemented appropriate data protection measures and who have agreed to comply with our data protection requirements. We do not allow our service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.</p>

        <h3>Where your information is stored</h3>
        <p>Personal information that we collect, or that is collected by our third party service providers, may be stored and processed in Australia or overseas. We take all reasonable steps to protect personal information no matter what country it is stored in. However, information collected may be retained, and may be stored, processed, accessed, and used in jurisdictions whose privacy laws may be different and less protective than those of in Australia. By using our site or giving us the personal information requested, you consent to any such transfer, processing and storage of personal information outside of Australia.</p>
        <p><b>If you are in the EU:</b> Compeat and some of our external service providers may not be in the country where you submitted your personal information. As a result, your personal information may be transferred to countries that have different levels of data protection laws to where you are. Where local data regulations require it, we have put in place security measures for the export of personal information from its country of origin. and made arrangements with those receiving your personal information, that they will put security measures in place and that your personal information is processed in accordance with EU data protection laws.</p>

        <h3>Data Retention and Deletion</h3>
        <p>We retain your personal information for as long as necessary to fulfill the purposes for which it was collected, comply with legal obligations, resolve disputes, and enforce our agreements. You have the right to request the deletion of your personal data at any time by contacting us at <a href="mailto:privacy@compeatnutrition.com" target="_blank" rel="noopener noreferrer">privacy@compeatnutrition.com</a>. We will promptly process your request and delete your personal data, subject to any legal obligations or legitimate interests that may require us to retain certain information.</p>

        <h3>Data Security</h3>
        <p>We take the security of your personal information seriously and implement appropriate technical and organizational measures to protect it from unauthorized access, use, or disclosure. These measures include, but are not limited to, encryption, access controls, and secure data storage. We regularly review and update our security measures to ensure the ongoing protection of your personal data.</p>

        <h3>Security of Your Personal Information</h3>
        <p>The security of your personal information is important to us. No method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, while we strive to protect your personal information, we cannot guarantee its absolute security and any transmission of personal information is at your own risk.</p>

        <h3>User Rights</h3>
        <p>You have the right to access, correct, or delete your personal data held by us. You also have the right to object to the processing of your personal data, restrict processing, and request data portability. To exercise any of these rights, please contact us at <a href="mailto:privacy@compeatnutrition.com" target="_blank" rel="noopener noreferrer">privacy@compeatnutrition.com</a>. We will respond to your request in accordance with applicable data protection laws</p>

        <h3>Your rights in relation to your personal information</h3>
        <p>You have, subject to certain exceptions allowed for by law, the right to access your personal information. If you would like to get access to, review or correct any of your personal information, please email us at <a href="mailto:privacy@compeatnutrition.com" target="_blank" rel="noopener noreferrer">privacy@compeatnutrition.com</a>.</p>
        <p>We can provide you with a copy of personal information held on our current records at no charge. However, we will not be obliged to provide you with a copy or give you access to your personal information if by doing so we would reveal personal or sensitive information about another person.</p>
        <p><b>If the GDPR applies</b> to the collection, disclosure or use of your personal information, then you are (among other things) also entitled to:</p>
        <ul>
          <li>receive transparent information regarding the processing of your personal information;</li>
          <li>access your personal information, including the right to obtain free of charge a copy of the personal information undergoing processing in a commonly available electronic format;</li>
          <li>rectification of incorrect personal information and completion of incomplete personal information;</li>
          <li>erasure of your personal information, including the “right to be forgotten”;</li>
          <li>restrict the processing of your personal information;</li>
          <li>your personal information being portable and accessible upon request;</li>
          <li>object to the processing of your personal information; and</li>
          <li>if you have consented to the processing of your personal information, you have the right to withdraw that consent.</li>
        </ul>
        <p>If you wish to exercise any of the above rights our contact information is at the end of this Privacy Policy.</p>
        <p>If we have disclosed your personal information to a third party, in accordance with this Privacy Policy, we will take reasonable steps to notify the third party of the updated information.</p>

        <h3>Retention of your personal information</h3>
        <p>We will only keep your information for as long as is necessary for the purposes for which you provided it to us. This means that we will keep your personal information for as long as we are providing services or selling goods to you. We may keep your personal information for a longer period in order to comply with our legal obligations.</p>
        <p>Personal information that is de-identified, is no longer considered to be personal information for the purpose of privacy legislation. If your personal information is de-identified, we may retain it for a period of time that extends beyond the period during which we are providing you with goods or services or are required to keep the information in order to comply with legal obligations.</p>

        <h3>Marketing</h3>
        <p>We may send you surveys or marketing communications to inform you of new products or services or other information that may be of interest. Third parties with whom we have shared your personal information may send you direct marketing communications.</p>
        <p>If you do not want to receive direct marketing communications from either us or a third party, it is easy to opt out. You can send us an email request at <a href="mailto:privacy@compeatnutrition.com" target="_blank" rel="noopener noreferrer">privacy@compeatnutrition.com</a>. You will continue to receive transactional or account communications (e.g., confirmation emails and account balance statements).</p>

        <h3>Website customisation</h3>
        <p>With your consent, and as set out in this Privacy Policy, we may use your personal information to build up a profile about you so that when you visit this site you don’t miss out on offers or information that is relevant to you.</p>
        <p>We, and our third party advertising service providers, may collect website usage information about users of our site for a number of purposes such as statistical analysis, sales and marketing research, tracking page usage and paths used by visitors through our site, targeting the site content, targeting our marketing campaigns and direct marketing emails, targeting our Internet banner advertisements on our website and on other websites and tracking use of our Internet banner advertisements and other links from our marketing partners' websites to our website. We may combine this information with other personal information we have collected about you eg how you arrived at our site and through cookies previously stored on your machine, as explained above.</p>

        <h3>Children</h3>
        <p>We do not intend to solicit or collect personal information from anyone under the age of 18. If you are under 18, you should not use or enter information on this site, including without limitation, any Interactive Areas of the site, without the consent of your parents or guardians and without first sending us an email at: <a href="mailto:privacy@compeatnutrition.com" target="_blank" rel="noopener noreferrer">privacy@compeatnutrition.com</a>.</p>

        <h3>Links</h3>
        <p>We may link to websites, including those of our third-party content providers and social media sites.  Our website may contain social media features such as Facebook “Like” buttons. These features may collect information about your device’s IP address, set cookies or link you to a social media website where you may post personal information. The sites of our third-party providers and social media sites may have different privacy policies and practices from those disclosed here. We assume no responsibility for the policies or practices of such sites and encourage you to become acquainted with them prior to use.</p>

        <h3>Complaints</h3>
        <p>If you have questions, complaints or concerns regarding this Privacy Policy, please contact us by writing to us at: <a href="mailto:privacy@compeatnutrition.com" target="_blank" rel="noopener noreferrer">privacy@compeatnutrition.com</a></p>
        <p>We treat all complaints about a breach of the privacy laws applicable to you seriously. Someone from Compeat will investigate your complaint and respond to you within a reasonable time. if you are not satisfied with our response, you have the right, depending on the jurisdiction, to make a complaint to the applicable regulator. In Australia, the applicable regulator is the Office of the Australian Information Commissioner. In European Union, the applicable regulator will be the local regulator in your jurisdiction in Europe.</p>

        <h3>Changes to This Privacy Policy</h3>
        <p>We reserve the right to change the terms of this privacy policy at any time. If there are material changes, we will post a notice of such changes here or on our home page and publish the effective date of the change. We encourage you to review this policy whenever you visit our site.</p>

        <h3>Contact Information</h3>
        <p>Compeat is the data controller responsible for your personal information for the purposes of the applicable EU data protection law.</p>

        <p>You can contact Compeat by emailing <a href="mailto:privacy@compeatnutrition.com" target="_blank" rel="noopener noreferrer">privacy@compeatnutrition.com</a></p>

        <p>&nbsp;</p>
        <p>Last update: 26 September 2022</p>
      </div>
    </PublicLayout>
  );
};

export default PrivacyPolicyView;