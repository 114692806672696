import React from 'react';
import LoginForm from "../../components/LoginForm";
import { IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonImg, IonPage, IonRow, IonText } from '@ionic/react';
import { version } from "../../../package.json";
import compeatLogo from '../../assets/compeat/logo_transparent_bw.png';
import { Link } from 'react-router-dom';

const SignInView = (props: {
  socialButtonActionLabel?: string;
  hideGetStartedFree?: boolean;
}) => {
  const socialButtonActionLabel = props.socialButtonActionLabel || 'Continue';

  if (localStorage.getItem('resetToken')) {
    localStorage.removeItem('resetToken');
  }
  document.title = "Sign in page";

  return (
    <IonPage className="authentication">
      <IonContent className="bg-sign-in">
        <IonGrid className="overlay">
          <IonRow className="d-flex justify-content-center align-items-center">
            <div className="app-navbar-notch">&nbsp;</div>
            <IonCol
              size-xs="12"
              size-sm="8"
              size-lg="6"
              size-xl="3"
              className="d-flex flex-column justify-content-center align-items-stretch"
            >
              <IonCard color="primary" className="ion-text-center ion-no-border">
                <IonImg src={compeatLogo} style={{ height: "102px" }} />
                <IonCardContent>
                  <LoginForm socialButtonActionLabel={socialButtonActionLabel} />
                </IonCardContent>
                <IonCardContent className="card-footer bg-white px-5">
                  <IonText color="medium">
                    <Link to={'/privacy'}>
                      Privacy Policy
                    </Link>
                    <span> | </span>
                    <Link to={'/email-confirm'}>
                      Forgot your password?
                    </Link>
                  </IonText>
                </IonCardContent>
              </IonCard>
              <IonText className="ion-text-right text-muted">
                <small>version: {version}</small>
              </IonText>
            </IonCol>
            <div className="app-navbar-notch">&nbsp;</div>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default SignInView;
