import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { IonContent, IonIcon, IonImg } from '@ionic/react';
import { chevronForward, closeOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Swiper as SwiperInterface, Keyboard, Pagination } from 'swiper';
import standOut from '../assets/illustrations/storyset_stand_out_31397.svg';
import eatingHealthy from '../assets/illustrations/storyset_eating_healthy_food_31398.svg';
import appData from '../assets/illustrations/storyset_app_data_31399.svg';

type SlideContent = {
  title?: string;
  icon: string;
  description?: any;
  btnText?: string;
  imgHeight?: string;
};

const slides: SlideContent[] = [
  {
    title: "Thanks for joining the Compeat Crew!",
    icon: standOut,
    description: <span>Our app is a tool to <strong>empower you</strong> to manage your nutrition</span>,
    btnText: 'Okay!'
  }, {
    icon: eatingHealthy,
    description: <span>The <strong>menus provided are generic</strong> and not designed to suit you and your dietary needs</span>,
    btnText: "Sure"
  }, {
    icon: appData,
    description: <span>The <strong>Edge Pay As You Go plan</strong> will provide you with access to your own dietitian for 24/7 services</span>,
    btnText: "Next"
  }
];

const WelcomeView = () => {
  document.title = 'Welcome to Compeat';
  const history = useHistory();
  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface | undefined>();

  if (Capacitor.isNativePlatform()) {
    StatusBar.setStyle({ style: Style.Light });
  }

  const handleNext = () => {
    if (swiperInstance) {
      if (swiperInstance.isEnd) {
        handleClose();
      } else {
        swiperInstance.slideNext();
      }
    }
  };

  const handleClose = () => {
    history.push('/signin');
  };

  return (
    <IonContent>
      <div className="fixed-top">
        <IonIcon
          icon={closeOutline}
          size="large"
          className="text-muted p-2 float-right mt-5"
          onClick={() => handleClose()} />
      </div>
      <Swiper
        className="h-100 bg-white"
        onSwiper={(swiper) => setSwiperInstance(swiper)}
        modules={[Keyboard, Pagination]}
        keyboard
        pagination
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index} className="bg-white">
            <div className="bg-white h-100 position-relative d-flex flex-column">
              <div className="h-100 position-relative">
                <div className="container-lg h-100 p-3 d-flex row no-gutters justify-content-center align-items-center">
                  <div>
                    <div className="px-5 pt-5">
                      <h4 className="text-primary mb-5 font-weight-bold" style={{ height: '50px' }}>{slide.title || ''}</h4>
                      <IonImg src={slide.icon} style={{ height: slide.imgHeight || '200px' }} />
                    </div>
                    <div className="px-2 py-4 d-flex justify-content-center">
                      <p className="mb-4 fs-12" style={{ width: '58%' }}>
                        {slide.description}
                      </p>
                    </div>
                  </div>
                  <div className="w-100 mb-auto">
                    <div className="float-right btn btn-link text-dark" onClick={() => handleNext()}>
                      {slide.btnText}&nbsp;<IonIcon icon={chevronForward} className="align-middle" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </IonContent>
  );
};

export default WelcomeView;
