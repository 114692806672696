import React from 'react';
import { IonSkeletonText, IonCheckbox } from '@ionic/react';
import { Link } from 'react-router-dom';
import { Circle, ArrowForward } from '@mui/icons-material';
import classNames from 'classnames';
import { Assessment } from '../../declarations/awsTypeDeclarations';
import { Form } from '../../API';

const FormCard = (props: { form?: Form | null; }) => {
  const { form } = props;

  return (
    <div className="w-100 mr-auto pl-1 py-1">
      <div className={classNames('w-100 p-0 bg-white', { 'btn rounded-sm shadow-sm': !form?.submittedAt })}>
        <div className="w-100 d-flex align-items-center p-2">
          <p className={classNames('w-75 fs-16 text-left m-0 py-1', { 'text-primary': !!form?.submittedAt })}>{form?.title ? form.title : <IonSkeletonText className="w-75 m-0" style={{ height: '24px' }} animated />}</p>
          {!!form?.submittedAt ?
            <IonCheckbox
              className="ml-auto"
              color="primary"
              checked
              disabled
            />
            :
            <ArrowForward className="ml-auto fs-16 text-grey-1103" />
          }
        </div>
      </div>
    </div>
  );
};

const FormList = (props: { assessment: Assessment | null | undefined; }) => {
  const { assessment } = props;
  const cardHeight = 58;

  return (
    <>
      <div className="pt-4">
        <h1 className="fs-24 font-weight-bold text-muted pb-4">{assessment?.insight?.title}</h1>
        <p className="fs-16 text-muted">This review is all about you, with no right or wrong answers, just opportunities for progress.</p>
        <p className="fs-16 text-muted">All replies will be treated as confidential within your High Performance environment.</p>
      </div>
      <div className="pb-5">
        <div className="d-flex flex-wrap align-items-center">
          <div style={{
            width: '8px',
            height: `${cardHeight * (assessment?.forms.length || 3)}px`,
            background: '#EBEBEB',
            borderRadius: '10px',
            transform: 'translateX(32px)'
          }} />
          <div className="flex-fill">
            {assessment ?
              assessment.forms.map((form, idx) => (
                form ? <div key={idx} className="w-100 d-flex align-items-center pl-5">
                  <Circle style={{ height: '16px', width: '16px', transform: `translateX(-28px)`, color: !!form.submittedAt ? '#EBEBEB' : '#CDCDCD' }} />
                  {!!form.submittedAt ?
                    <FormCard form={form} />
                    :
                    <Link to={`/insights/assessment/${assessment.id}/form/${form.typeformId}`} className="w-100">
                      <FormCard form={form} />
                    </Link>
                  }
                </div> : null
              ))
              :
              [...Array(3)].map((_, idx: number) => (
                <div key={idx} className="w-100 d-flex align-items-center pl-5">
                  <Circle style={{ height: '16px', width: '16px', transform: `translateX(-28px)`, color: '#CDCDCD' }} />
                  <FormCard />
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default FormList;