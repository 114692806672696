/* tslint:disable */
/* eslint-disable */

export const updateAlert = /* GraphQL */ `
  mutation UpdateAlert(
    $input: UpdateAlertInput!
    $condition: ModelAlertConditionInput
  ) {
    updateAlert(input: $input, condition: $condition) {
      id
      content
      action
      isRead
      createdAt
      alertUserId
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      content
      createdAt
      updatedAt
      readBy
      messageAuthorId
      messageConversationId
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      content
      createdAt
      messageAuthorId
      readBy
      messageConversationId
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      content
      createdAt
      messageAuthorId
      readBy
      messageConversationId
      updatedAt
    }
  }
`;
export const createDevice = /* GraphQL */ `
  mutation CreateDevice(
    $input: CreateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    createDevice(input: $input, condition: $condition) {
      id
      token
      platform
      bundleId
      deviceArn
      deviceUserId
      createdAt
      updatedAt
    }
  }
`;
export const deleteDevice = /* GraphQL */ `
  mutation DeleteDevice(
    $input: DeleteDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    deleteDevice(input: $input, condition: $condition) {
      id
      token
      platform
      bundleId
      deviceArn
      deviceUserId
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      authId
      email
      firstName
      lastName
      profile {
        sex
        pronouns
        ageBracket
      }
      gender
      bodyType
      height
      weight
      bodyFat
      activityFactor
      nutritionNotes
      joinReason
      trainingCalendarLink
      guessedLocation
      journal {
        weight
        waist
        appetiteRating
        energyRating
        sleepRating
        motivationRating
        wellnessRating
        stressRating
        nutritionRating
        createdAt
      }
      events {
        name
        details
        date
        location
        goal
        preEventFoodRituals
        nutritionProducts
        nutritionLogistics
        issues
        leadupEvents
        accomodation
        leadupEventDate
        leadupEventDetails
        createdAt
      }
      sports {
        name
        level
        comments
      }
      goals {
        description
        comments
        progress {
          rating
          createdAt
        }
        targetDate
        createdAt
      }
      teams {
        name
        joinedAt
      }
      foodDiary {
        date
        day
        items {
          time
          detail
          comments
        }
      }
      nutritionInformation {
        allergies
        supplements
        dislikes
        comments
        preferences
        goals {
          description
          comments
          progress {
            rating
            createdAt
          }
          targetDate
          createdAt
        }
        dietaryRequirements
      }
      nutritionAssessments {
        requestDate
        responseStartDate
        responseCompleteDate
        responses {
          question
          answer
          comments
          score
        }
        score
      }
      lifestyle {
        cookingSkills
        cookingMethods
        easyNights
        homeSituation
        peopleToFeed
        workDays
        nightsEatingOut
        workShifts
        breakfastOnTheRun
        occupation
        preferences
      }
      healthInformation {
        medicalConditions
        medication
        isMenstruating
        regularPeriod
        periodStoppedOver3Months
      }
      membership {
        id
        amount
        plan
        discount
        createdAt
        expiresAt
        cancelAtPeriodEnd
      }
      stripeCustomerId
      permission {
        hasMeals
        hasHabits
      }
      priority {
        bonking
        energyLevels
        gutUpset
        manageCramping
        muscleMass
        overallHealth
        performance
        raceDayNutrition
        trainingAdaptations
        weightLoss
        createdAt
      }
      priorityConfidence {
        bonking
        energyLevels
        gutUpset
        manageCramping
        muscleMass
        overallHealth
        performance
        raceDayNutrition
        trainingAdaptations
        weightLoss
        createdAt
      }
      lastActiveAt
      createdAt
      updatedAt
      paymentMethod {
        id
        type
        brand
        exp_month
        exp_year
        last4
      }
      status
      devices {
        items {
          id
          token
          platform
          bundleId
          deviceArn
          deviceUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          content
          createdAt
          messageAuthorId
          readBy
          messageConversationId
          updatedAt
        }
        nextToken
      }
      specialists {
        items {
          id
          clientId
          specialistId
          specialist {
            id
            displayName
            businessName
            qualifications
            clinicalSpecialities
            sportSpecialities
            bio
            trivia
            providerNumber
            businessIdNumber
            gender
            image
            video
            status
            isAvailable
            clientLimit
            ownerId
            createdAt
            updatedAt
          }
          messages {
            items {
              id
              content
              createdAt
              messageAuthorId
              readBy
              messageConversationId
              updatedAt
            }
            nextToken
          }
          status
          servicingNote
          files {
            title
            url
            type
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      editors
      viewers
      contactInfoId
      contactInfo {
        id
        userId
        firstName
        lastName
        avatar
        email
        phone
        permissionToShare
        createdAt
        updatedAt
      }
      clientProfile {
        items {
          id
          userId
          specialistId
          status
          servicingNote
          settings
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      authId
      createdAt
      updatedAt
      status
      guessedLocation
      permission {
        hasMeals
        hasHabits
      }
      devices {
        items {
          id
          token
          platform
          bundleId
          deviceArn
          deviceUserId
          user {
            id
            authId
            email
            firstName
            lastName
            gender
            dateOfBirth
            bodyType
            height
            weight
            bodyFat
            activityFactor
            nutritionNotes
            joinReason
            trainingCalendarLink
            lastActiveAt
            createdAt
            updatedAt
            status
            editors
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          content
          createdAt
          messageAuthorId
          readBy
          author {
            id
            authId
            email
            firstName
            lastName
            gender
            dateOfBirth
            bodyType
            height
            weight
            bodyFat
            activityFactor
            nutritionNotes
            joinReason
            trainingCalendarLink
            lastActiveAt
            createdAt
            updatedAt
            status
            editors
          }
          messageConversationId
          conversation {
            id
            clientId
            specialistId
            status
            servicingNote
            createdAt
            updatedAt
          }
          updatedAt
        }
        nextToken
      }
      specialists {
        items {
          id
          clientId
          specialistId
          client {
            id
            authId
            email
            firstName
            lastName
            gender
            dateOfBirth
            bodyType
            height
            weight
            bodyFat
            activityFactor
            nutritionNotes
            joinReason
            trainingCalendarLink
            lastActiveAt
            createdAt
            updatedAt
            status
            editors
          }
          specialist {
            id
            displayName
            businessName
            qualifications
            clinicalSpecialities
            sportSpecialities
            bio
            trivia
            providerNumber
            businessIdNumber
            gender
            dateOfBirth
            image
            video
            status
            isAvailable
            clientLimit
            ownerId
            createdAt
            updatedAt
          }
          messages {
            nextToken
          }
          status
          servicingNote
          files {
            title
            url
            type
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      editors
      viewers
      contactInfoId
      contactInfo {
        id
        userId
        firstName
        lastName
        avatar
        email
        phone
        permissionToShare
        createdAt
        updatedAt
      }
      clientProfile {
        items {
          id
          userId
          specialistId
          status
          servicingNote
          settings
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createContactInfo = /* GraphQL */ `
  mutation CreateContactInfo(
    $input: CreateContactInfoInput!
    $condition: ModelContactInfoConditionInput
  ) {
    createContactInfo(input: $input, condition: $condition) {
      id
      userId
      firstName
      lastName
      avatar
      email
      phone
      permissionToShare
      createdAt
      updatedAt
    }
  }
`;
export const updateContactInfo = /* GraphQL */ `
  mutation UpdateContactInfo(
    $input: UpdateContactInfoInput!
    $condition: ModelContactInfoConditionInput
  ) {
    updateContactInfo(input: $input, condition: $condition) {
      id
      userId
      firstName
      lastName
      avatar
      email
      phone
      permissionToShare
      createdAt
      updatedAt
    }
  }
`;
export const updateInvitation = /* GraphQL */ `
  mutation UpdateInvitation(
    $input: UpdateInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    updateInvitation(input: $input, condition: $condition) {
      id
      clientId
      invitedUserId
      email
      contactType
      firstName
      lastName
      status
      createdAt
      updatedAt
    }
  }
`;


export const createHabits = /* GraphQL */ `
  mutation CreateHabits(
    $input: CreateHabitsInput!
    $condition: ModelHabitsConditionInput
  ) {
    createHabits(input: $input, condition: $condition) {
      id
      identityGoal
      weeklyReflectionDay
      log {
        content
        type
        createdAt
      }
      behaviours {
        id
        behaviour
        status
        colour
      }
      actions {
        id
        description
        status
        behaviour
        behaviourId
      }
      status
      userId
      editors
      viewers
      createdAt
      updatedAt
    }
  }
`;
export const updateHabits = /* GraphQL */ `
  mutation UpdateHabits(
    $input: UpdateHabitsInput!
    $condition: ModelHabitsConditionInput
  ) {
    updateHabits(input: $input, condition: $condition) {
      id
      identityGoal
      weeklyReflectionDay
      log {
        content
        type
        createdAt
      }
      behaviours {
        id
        behaviour
        status
        colour
      }
      actions {
        id
        description
        status
        behaviour
        behaviourId
      }
      status
      userId
      editors
      viewers
      createdAt
      updatedAt
    }
  }
`;
export const updateAssessment = /* GraphQL */ `
  mutation UpdateAssessment(
    $input: UpdateAssessmentInput!
    $condition: ModelAssessmentConditionInput
  ) {
    updateAssessment(input: $input, condition: $condition) {
      id
      userId
      forms {
        typeformId
        title
        submittedAt
      }
      insightId
      insight {
        id
        teamId
        dueDate
        userCount
        createdAt
        updatedAt
      }
      status
      completedAt
      createdAt
      updatedAt
    }
  }
`;