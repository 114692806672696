import React, { ReactNode } from 'react';
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/react';

const UnauthenticatedLayout = (props: { children: ReactNode; title: string; }) => {
  const { children, title } = props;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="dashboard-toolbar">
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="h-100 container-fluid p-0">
          <div className="h-100 row no-gutters d-flex justify-content-center">
            <div className="col-12 col-lg-9 col-xxl-7">
              <div className="h-100 shadow-sm bg-white">
                <div className="h-100 w-100 p-4">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default UnauthenticatedLayout;