import React, { useState, Dispatch, SetStateAction } from 'react';

interface ModalContextInterface {
  showNotification: boolean;
  setShowNotification: Dispatch<SetStateAction<boolean>>;
  showEdgeUpgrade: boolean;
  setShowEdgeUpgrade: Dispatch<SetStateAction<boolean>>;
  showCancel: boolean;
  setShowCancel: Dispatch<SetStateAction<boolean>>;
  showDelete: boolean;
  setShowDelete: Dispatch<SetStateAction<boolean>>;
  deleteProps: {deleteError: boolean};
  setDeleteProps: Dispatch<SetStateAction<{deleteError: boolean}>>;
  showSwitchProfile: boolean;
  setShowSwitchProfile: Dispatch<SetStateAction<boolean>>;
  showHabitReflection: boolean;
  setShowHabitReflection: Dispatch<SetStateAction<boolean>>;
}

const ModalContext = React.createContext<ModalContextInterface | null>(null);

const ModalProvider: React.FC = (props) => {
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [showEdgeUpgrade, setShowEdgeUpgrade] = useState<boolean>(false);
  const [showHabitReflection, setShowHabitReflection] = useState<boolean>(false);
  const [showCancel, setShowCancel] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [deleteProps, setDeleteProps] = useState<{deleteError: boolean}>({deleteError: false});
  const [showSwitchProfile, setShowSwitchProfile] = useState<boolean>(false);

  return (
    <ModalContext.Provider value={{
      showNotification,
      setShowNotification,
      showEdgeUpgrade,
      setShowEdgeUpgrade,
      showCancel,
      setShowCancel,
      showDelete,
      setShowDelete,
      deleteProps,
      setDeleteProps,
      showSwitchProfile,
      setShowSwitchProfile,
      showHabitReflection,
      setShowHabitReflection
    }} {...props} />
  );
};

const useModal = () => {
  const context = React.useContext(ModalContext) as ModalContextInterface;
  if (context === undefined) {
    throw new Error(`useModal must be used within a ModalProvider`);
  }
  return context;
};

export { useModal };
export default ModalProvider;
